import { settlementConstants } from "@_constants/settlement_portal.constants";
import { settlementService } from "@_services/settlement_portal.service";

export const settlementActions = {
  getSettlement,
  updatePortalAddress,
  updateLegalContact,
  clearStatusMessage,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return {
      type: settlementConstants.SETTLEMENT_CLEAR_STATUS_MESSAGE,
      stateName,
    };
  }
}

function getSettlement() {
  return (dispatch) => {
    dispatch(request());
    settlementService.getSettlement().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: settlementConstants.SETTLEMENT_GET_REQUEST };
  }
  function success(data) {
    return { type: settlementConstants.SETTLEMENT_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: settlementConstants.SETTLEMENT_GET_FAILURE, error };
  }
}

function updatePortalAddress(data) {
  return (dispatch) => {
    dispatch(request());
    settlementService.updatePortalAddress(data).then(
      (results) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: settlementConstants.SETTLEMENT_PORTAL_ADDRESS_UPDATE_REQUEST,
    };
  }
  function success(data) {
    return {
      type: settlementConstants.SETTLEMENT_PORTAL_ADDRESS_UPDATE_SUCCESS,

      data,
    };
  }
  function failure(error) {
    return {
      type: settlementConstants.SETTLEMENT_PORTAL_ADDRESS_UPDATE_FAILURE,
      error,
    };
  }
}

function updateLegalContact(data) {
  return (dispatch) => {
    dispatch(request());
    settlementService.updateLegalContact(data).then(
      (results) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: settlementConstants.SETTLEMENT_LEGAL_CONTACT_UPDATE_REQUEST,
    };
  }
  function success(id, data) {
    return {
      type: settlementConstants.SETTLEMENT_LEGAL_CONTACT_UPDATE_SUCCESS,
      id,
      data,
    };
  }
  function failure(error) {
    return {
      type: settlementConstants.SETTLEMENT_LEGAL_CONTACT_UPDATE_FAILURE,
      error,
    };
  }
}
