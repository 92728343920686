import { authConstants } from "@_constants/auth.constants";
import { history, clearToken } from "@_utils/helpers";
import { authService } from "@_services/auth.service";

export const authActions = {
  login,
  logout,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request());
    authService.login(email, password).then(
      (user) => {
        dispatch(success(user));
        history.push("/cases");
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    clearToken();
    dispatch({ type: authConstants.LOGOUT });
    history.push("/login");
  };
}
