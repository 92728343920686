import { languageConstants } from "@_constants/language.constants";
import { languageService } from "@_services/language.service";

export const languageActions = {
  getLanguages,
  getTranslations,
  addTranslation,
  deleteTranslation,
  updateLoginText,
  updateCaseText,
  updateAboutUsText,
  updateFaqs,
  clearStatusMessage,
  selectTranslation,
  updateDefaultTranslation,
  updateActiveTranslation,
  updateDeactivateTranslation,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: languageConstants.LANGUAGE_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function getLanguages() {
  return (dispatch) => {
    dispatch(request());
    languageService.getLanguages().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.LANGUAGE_GET_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.LANGUAGE_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.LANGUAGE_GET_FAILURE, error };
  }
}

function getTranslations() {
  return (dispatch) => {
    dispatch(request());
    languageService.getTranslations().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.TRANSLATION_GET_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.TRANSLATION_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.TRANSLATION_GET_FAILURE, error };
  }
}

function addTranslation(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.addTranslation(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.TRANSLATION_POST_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.TRANSLATION_POST_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.TRANSLATION_POST_FAILURE, error };
  }
}

function deleteTranslation(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.deleteTranslation(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.TRANSLATION_DELETE_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.TRANSLATION_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.TRANSLATION_DELETE_FAILURE, error };
  }
}

function updateLoginText(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateLoginText(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.LOGIN_TEXT_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.LOGIN_TEXT_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.LOGIN_TEXT_UPDATE_FAILURE, error };
  }
}

function updateCaseText(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateCaseText(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.CASE_OVERVIEW_TEXT_UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: languageConstants.CASE_OVERVIEW_TEXT_UPDATE_SUCCESS,

      data,
    };
  }
  function failure(error) {
    return { type: languageConstants.CASE_OVERVIEW_TEXT_UPDATE_FAILURE, error };
  }
}

function updateAboutUsText(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateAboutUsText(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.ABOUT_US_TEXT_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.ABOUT_US_TEXT_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.ABOUT_US_TEXT_UPDATE_FAILURE, error };
  }
}

function updateFaqs(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateFaqs(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.FAQ_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: languageConstants.FAQ_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: languageConstants.FAQ_UPDATE_FAILURE, error };
  }
}

function updateDefaultTranslation(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateDefaultTranslation(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.DEFAULT_TRANSLATION_UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: languageConstants.DEFAULT_TRANSLATION_UPDATE_SUCCESS,

      data,
    };
  }
  function failure(error) {
    return {
      type: languageConstants.DEFAULT_TRANSLATION_UPDATE_FAILURE,
      error,
    };
  }
}

function updateActiveTranslation(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateActiveTranslation(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.ACTIVATE_TRANSLATION_UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: languageConstants.ACTIVATE_TRANSLATION_UPDATE_SUCCESS,

      data,
    };
  }
  function failure(error) {
    return {
      type: languageConstants.ACTIVATE_TRANSLATION_UPDATE_FAILURE,
      error,
    };
  }
}

function updateDeactivateTranslation(data) {
  return (dispatch) => {
    dispatch(request());
    languageService.updateDeactivateTranslation(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: languageConstants.DEACTIVATE_TRANSLATION_UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: languageConstants.DEACTIVATE_TRANSLATION_UPDATE_SUCCESS,

      data,
    };
  }
  function failure(error) {
    return {
      type: languageConstants.DEACTIVATE_TRANSLATION_UPDATE_FAILURE,
      error,
    };
  }
}

function selectTranslation(data) {
  return (dispatch) => {
    dispatch(request(data));
  };

  function request() {
    return { type: languageConstants.SELECT_TRANSLATION, data };
  }
}
