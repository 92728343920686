import React, { useEffect, useState } from "react";
import styles from "./BarChartBlock.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataFromJwt, randomColorGenerator } from "@_utils/helpers";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
} from "recharts";
import { barChartColors } from "@_configs/dashboard.config";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { MenuItem } from "@material-ui/core";
import { dashboardActions } from "@_actions/dashboard.actions";
import { userConstants } from "@_constants/user.constants";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function BarChartBlock({
  selectedChart,
  ChartData,
  generalChartSettings,
  chartIndex,
  chartsViewWidth,
}) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const jwtData = getUserDataFromJwt();
  const dashboardSettings = dashboardReducer.settings;
  const [chartSettings, setChartSettings] = useState({
    ...generalChartSettings,
    option1: selectedChart.date_group_by,
    option2: selectedChart.subgroup,
  });

  let chartYear = 0;

  useEffect(() => {
    setChartSettings((prev) => {
      return {
        ...prev,
        ...generalChartSettings,
      };
    });
  }, [generalChartSettings]);

  const chartOption3 = () => {
    if (selectedChart.class === "Payments") {
      if (selectedChart.currency) {
        return selectedChart.currency;
      } else if (dashboardSettings?.currencies?.length > 1) {
        return dashboardSettings?.currencies[0];
      } else return "";
    } else if (
      selectedChart.class === "Emails" &&
      selectedChart.date_group_by === "received"
    ) {
      return selectedChart.subgroup;
    } else {
      return "all";
    }
  };

  useEffect(() => {
    if (dashboardReducer.status.SETTINGS_SET === "success") {
      setChartSettings((prev) => {
        return {
          ...prev,
          option1: selectedChart.date_group_by,
          option2:
            selectedChart.class === "Emails" &&
            selectedChart.date_group_by === "received"
              ? "all"
              : selectedChart.subgroup,
          option3: chartOption3(),
        };
      });
    }
    // eslint-disable-next-line
  }, [dashboardReducer.status.SETTINGS_SET]);

  useEffect(() => {
    setChartSettings((prev) => {
      return {
        ...prev,
        option1: selectedChart.date_group_by,
        option2:
          selectedChart.class === "Emails" &&
          selectedChart.date_group_by === "received"
            ? "all"
            : selectedChart.subgroup,
        option3: chartOption3(),
      };
    });
    // eslint-disable-next-line
  }, [selectedChart]);

  const allowedToShowOption = (option) => {
    if (jwtData.user_role === userConstants.USER_ROLE_AGENT) {
      if (option.includes("user")) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (
      dashboardReducer.status.SETTINGS_GET !== "success" &&
      dashboardReducer.status.SETTINGS_SET !== "success" &&
      Object.keys(chartSettings).length > 3
    ) {
      dispatch(
        dashboardActions.getTimeBasedChart(
          selectedChart.class,
          chartSettings,
          chartIndex
        )
      );
    }
    // eslint-disable-next-line
  }, [dispatch, chartSettings, selectedChart.class, chartIndex]);

  const setEditedChart = (event) => {
    event.persist();
    setChartSettings((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const DateLabel = () => {
    let DateLabel = "";
    switch (chartSettings.date_grouping) {
      case "daily":
        DateLabel = "Day";
        break;

      case "weekly":
        DateLabel = "Week";
        break;

      case "monthly":
        DateLabel = "Month";
        break;

      case "yearly":
        DateLabel = "Year";
        break;

      default:
        DateLabel = "Date";
        break;
    }
    return DateLabel;
  };

  const getWeekDateRange = (weekNumber, year) => {
    const firstDayOfYear = new Date(year, 0, 1);
    const daysToAdd = (weekNumber - 1) * 7;
    const firstDayOfWeek = new Date(
      firstDayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd)
    );
    const dayOfWeek = firstDayOfWeek.getDay();
    const startDate = new Date(
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() - dayOfWeek + 1)
    );
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return {
      startDate,
      endDate,
    };
  };
  const CustomTooltip = ({ active, payload, label, year }) => {
    if (active && payload && payload.length) {
      const total = payload.reduce((sum, entry) => sum + entry.value, 0);

      let weekRange = "";
      if (chartSettings.date_grouping === "weekly" && payload[0].payload) {
        const weekDateObject = getWeekDateRange(
          payload[0].payload.label,
          payload[0].payload.year
        );
        const startDate = weekDateObject.startDate;
        const endDate = weekDateObject.endDate;
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth() + 1;
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth() + 1;
        weekRange = `${startDay}.${startMonth}. - ${endDay}.${endMonth}.`;
      }
      return (
        <Scrollbar maxHeight="300px">
          <div className={styles.custom_tooltip}>
            <p className={styles.custom_tooltip_label}>{`${DateLabel()} : ${
              chartSettings.date_grouping === "weekly" && payload[0].payload
                ? weekRange
                : label
            }`}</p>
            {payload.map((entry, index) => (
              <p
                key={`item-${index}`}
                className={styles.tooltip_entry}
                style={{ color: entry.color }}
              >
                {chartSettings?.option2 &&
                  chartSettings.option2 !== "none" &&
                  `${entry.name} : ${entry.value}`}
              </p>
            ))}
            <p
              className={styles.custom_tooltip_total}
            >{`Total ${selectedChart?.class} : ${total}`}</p>
          </div>
        </Scrollbar>
      );
    }

    return null;
  };
  const renderYearTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value: year, offset } = payload;

    if (year && year !== chartYear) {
      const yearDivision = (
        <>
          <text
            x={x}
            y={y + 5}
            className={styles.year_label}
            scaleToFit={true}
            // transform={`rotate(80, ${x}, ${y + 5})`}
            textAnchor="middle"
          >
            {year}
          </text>

          <path d={`M${x - offset},${y}v${-35}`} stroke="#4B4B4B" />
        </>
      );
      chartYear = year;
      return yearDivision;
    }
    return null;
  };

  const renderOption1ChartView = () => {
    return dashboardSettings?.available_charts
      ?.find((availableChart) => availableChart.class === selectedChart.class)
      ?.option1?.map((option) => (
        <MenuItem value={option.value}>{option.label}</MenuItem>
      ));
  };

  const renderOption2ChartView = () => {
    return selectedChart?.class !== "Emails" ||
      (selectedChart?.class === "Emails" && chartSettings.option1 === "sent")
      ? dashboardSettings?.available_charts
          ?.find(
            (availableChart) => availableChart.class === selectedChart.class
          )
          ?.option2?.map(
            (option) =>
              chartSettings.option1 &&
              allowedToShowOption(option.value) && (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              )
          )
      : dashboardSettings?.available_charts
          ?.find(
            (availableChart) => availableChart.class === selectedChart.class
          )
          ?.option3?.map(
            (option) =>
              chartSettings.option1 && (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              )
          );
  };

  const chartOption2Name = () => {
    return `${
      selectedChart?.class !== "Emails" ||
      (selectedChart?.class === "Emails" && chartSettings.option1 === "sent")
        ? "option2"
        : "option3"
    }`;
  };

  const chartOption2Value = () => {
    return selectedChart?.class !== "Emails" ||
      (selectedChart?.class === "Emails" && chartSettings.option1 === "sent")
      ? chartSettings.option2 || "none"
      : chartSettings.option3 || "all";
  };

  const chartOption2Label = () => {
    return `${
      selectedChart?.class !== "Emails" ||
      (selectedChart?.class === "Emails" && chartSettings.option1 === "sent")
        ? "Subgroup"
        : "Filter"
    }`;
  };

  return (
    <div className={styles.chart}>
      <div className={styles.chart_title}>
        <h3>{selectedChart?.class}</h3>
        <div className={styles.chart_title_filter}>
          <TextfieldInput
            label="Date group by"
            select
            name="option1"
            size="small"
            className={styles.select}
            value={chartSettings.option1 || " "}
            onChange={setEditedChart}
          >
            {renderOption1ChartView()}
          </TextfieldInput>
        </div>
        {selectedChart?.class === "Payments" &&
          generalChartSettings?.currencies?.length > 1 && (
            <div className={styles.chart_currency_filter}>
              <TextfieldInput
                label="Currency"
                select
                name="option3"
                size="small"
                className={styles.select}
                value={
                  chartSettings.option3 || generalChartSettings?.currencies[0]
                }
                onChange={setEditedChart}
              >
                {generalChartSettings?.currencies.map((currency) => (
                  <MenuItem value={currency}>{currency}</MenuItem>
                ))}
              </TextfieldInput>
            </div>
          )}
        <div className={styles.chart_title_filter}>
          <TextfieldInput
            label={chartOption2Label()}
            select
            name={chartOption2Name()}
            size="small"
            className={styles.select}
            value={chartOption2Value()}
            onChange={setEditedChart}
          >
            {renderOption2ChartView()}
          </TextfieldInput>
        </div>
      </div>
      <BarChart
        width={Number(chartsViewWidth) * 0.45}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={ChartData?.data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis xAxisId={0} dataKey="label" />
        <XAxis
          dataKey="year"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={renderYearTick}
          label={{ value: "", angle: 0, position: "bottom" }}
          xAxisId="year"
        />

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        {/* uncomment if legend is needed in the charts */}
        {/* {(chartSettings?.option1 === "received" ||
          chartSettings?.option2 !== "none") && (
          <Legend wrapperStyle={{ marginTop: 20 }} />
        )} */}
        {ChartData?.series?.map((seriesName, i) => (
          <Bar
            dataKey={seriesName}
            stackId="a"
            fill={barChartColors[i] || randomColorGenerator()}
          />
        ))}
      </BarChart>
    </div>
  );
}

export default BarChartBlock;
