export const caseConstants = {
  CASE_CLEAR_STATE_MESSGAE: "CASE_CLEAR_STATE_MESSGAE",

  CASE_GET_REQUEST: "CASE_GET_REQUEST",
  CASE_GET_SUCCESS: "CASE_GET_SUCCESS",
  CASE_GET_FAILURE: "CASE_GET_FAILURE",

  CASE_UPDATE_OPPONENT_DETAILS_REQUEST: "CASE_UPDATE_OPPONENT_DETAILS_REQUEST",
  CASE_UPDATE_OPPONENT_DETAILS_SUCCESS: "CASE_UPDATE_OPPONENT_DETAILS_SUCCESS",
  CASE_UPDATE_OPPONENT_DETAILS_FAILURE: "CASE_UPDATE_OPPONENT_DETAILS_FAILURE",

  CASE_UPDATE_LOG_REQUEST: "CASE_UPDATE_LOG_REQUEST",
  CASE_UPDATE_LOG_SUCCESS: "CASE_UPDATE_LOG_SUCCESS",
  CASE_UPDATE_LOG_FAILURE: "CASE_UPDATE_LOG_FAILURE",

  CASE_ADD_LOG_REQUEST: "CASE_ADD_LOG_REQUEST",
  CASE_ADD_LOG_SUCCESS: "CASE_ADD_LOG_SUCCESS",
  CASE_ADD_LOG_FAILURE: "CASE_ADD_LOG_FAILURE",

  CASE_UPDATE_IMPRINT_REQUEST: "CASE_UPDATE_IMPRINT_REQUEST",
  CASE_UPDATE_IMPRINT_SUCCESS: "CASE_UPDATE_IMPRINT_SUCCESS",
  CASE_UPDATE_IMPRINT_FAILURE: "CASE_UPDATE_IMPRINT_FAILURE",

  CASE_UPDATE_FINDING_IMAGE_DATA_REQUEST:
    "CASE_UPDATE_FINDING_IMAGE_DATA_REQUEST",
  CASE_UPDATE_FINDING_IMAGE_DATA_SUCCESS:
    "CASE_UPDATE_FINDING_IMAGE_DATA_SUCCESS",
  CASE_UPDATE_FINDING_IMAGE_DATA_FAILURE:
    "CASE_UPDATE_FINDING_IMAGE_DATA_FAILURE",

  CASE_UPDATE_FINDING_PRICE_REQUEST: "CASE_UPDATE_FINDING_PRICE_REQUEST",
  CASE_UPDATE_FINDING_PRICE_SUCCESS: "CASE_UPDATE_FINDING_PRICE_SUCCESS",
  CASE_UPDATE_FINDING_PRICE_FAILURE: "CASE_UPDATE_FINDING_PRICE_FAILURE",

  CASE_UPDATE_CLAIM_DETAILS_REQUEST: "CASE_UPDATE_CLAIM_DETAILS_REQUEST",
  CASE_UPDATE_CLAIM_DETAILS_SUCCESS: "CASE_UPDATE_CLAIM_DETAILS_SUCCESS",
  CASE_UPDATE_CLAIM_DETAILS_FAILURE: "CASE_UPDATE_CLAIM_DETAILS_FAILURE",

  CASE_GET_CHARGE_DETAILS_REQUEST: "CASE_GET_CHARGE_DETAILS_REQUEST",
  CASE_GET_CHARGE_DETAILS_SUCCESS: "CASE_GET_CHARGE_DETAILS_SUCCESS",
  CASE_GET_CHARGE_DETAILS_FAILURE: "CASE_GET_CHARGE_DETAILS_FAILURE",

  CASE_DELETE_CALLBACK_REQUEST_REQUEST: "CASE_DELETE_CALLBACK_REQUEST_REQUEST",
  CASE_DELETE_CALLBACK_REQUEST_SUCCESS: "CASE_DELETE_CALLBACK_REQUEST_SUCCESS",
  CASE_DELETE_CALLBACK_REQUEST_FAILURE: "CASE_DELETE_CALLBACK_REQUEST_FAILURE",

  CASE_UPDATE_STATE_ACTIVATE_REQUEST: "CASE_UPDATE_STATE_ACTIVATE_REQUEST",
  CASE_UPDATE_STATE_ACTIVATE_SUCCESS: "CASE_UPDATE_STATE_ACTIVATE_SUCCESS",
  CASE_UPDATE_STATE_ACTIVATE_FAILURE: "CASE_UPDATE_STATE_ACTIVATE_FAILURE",

  CASE_UPDATE_STATE_CANCEL_REQUEST: "CASE_UPDATE_STATE_CANCEL_REQUEST",
  CASE_UPDATE_STATE_CANCEL_SUCCESS: "CASE_UPDATE_STATE_CANCEL_SUCCESS",
  CASE_UPDATE_STATE_CANCEL_FAILURE: "CASE_UPDATE_STATE_CANCEL_FAILURE",

  CASE_UPDATE_STATE_RESET_REQUEST: "CASE_UPDATE_STATE_RESET_REQUEST",
  CASE_UPDATE_STATE_RESET_SUCCESS: "CASE_UPDATE_STATE_RESET_SUCCESS",
  CASE_UPDATE_STATE_RESET_FAILURE: "CASE_UPDATE_STATE_RESET_FAILURE",

  CASE_UPDATE_COUNTRY_REQUEST: "CASE_UPDATE_COUNTRY_REQUEST",
  CASE_UPDATE_COUNTRY_SUCCESS: "CASE_UPDATE_COUNTRY_SUCCESS",
  CASE_UPDATE_COUNTRY_FAILURE: "CASE_UPDATE_COUNTRY_FAILURE",

  CASE_ADD_ADDITIONAL_FEES_REQUEST: "CASE_ADD_ADDITIONAL_FEES_REQUEST",
  CASE_ADD_ADDITIONAL_FEES_SUCCESS: "CASE_ADD_ADDITIONAL_FEES_SUCCESS",
  CASE_ADD_ADDITIONAL_FEES_FAILURE: "CASE_ADD_ADDITIONAL_FEES_FAILURE",

  CASE_UPDATE_LICENSE_STATE_REQUEST: "CASE_UPDATE_LICENSE_STATE_REQUEST",
  CASE_UPDATE_LICENSE_STATE_SUCCESS: "CASE_UPDATE_LICENSE_STATE_SUCCESS",
  CASE_UPDATE_LICENSE_STATE_FAILURE: "CASE_UPDATE_LICENSE_STATE_FAILURE",
  CASE_RESTORE_TRIGGER_SENDING_EMAIL_FAILED:
    "CASE_RESTORE_TRIGGER_SENDING_EMAIL_FAILED",

  CASE_DELETE_LICENSE_REQUEST: "CASE_DELETE_LICENSE_REQUEST",
  CASE_DELETE_LICENSE_SUCCESS: "CASE_DELETE_LICENSE_SUCCESS",
  CASE_DELETE_LICENSE_FAILURE: "CASE_DELETE_LICENSE_FAILURE",

  CASE_DELETE_FINDING_REQUEST: "CASE_DELETE_FINDING_REQUEST",
  CASE_DELETE_FINDING_SUCCESS: "CASE_DELETE_FINDING_SUCCESS",
  CASE_DELETE_FINDING_FAILURE: "CASE_DELETE_FINDING_FAILURE",

  CASE_ADD_FINDING_REQUEST: "CASE_ADD_FINDING_REQUEST",
  CASE_ADD_FINDING_SUCCESS: "CASE_ADD_FINDING_SUCCESS",
  CASE_ADD_FINDING_FAILURE: "CASE_ADD_FINDING_FAILURE",

  CASE_ADD_FLOW_REQUEST: "CASE_ADD_FLOW_REQUEST",
  CASE_ADD_FLOW_SUCCESS: "CASE_ADD_FLOW_SUCCESS",
  CASE_ADD_FLOW_FAILURE: "CASE_ADD_FLOW_FAILURE",

  CASE_ADD_TRANSLATION_REQUEST: "CASE_ADD_TRANSLATION_REQUEST",
  CASE_ADD_TRANSLATION_SUCCESS: "CASE_ADD_TRANSLATION_SUCCESS",
  CASE_ADD_TRANSLATION_FAILURE: "CASE_ADD_TRANSLATION_FAILURE",

  CASE_SEND_EMAIL_REQUEST: "CASE_SEND_EMAIL_REQUEST",
  CASE_SEND_EMAIL_SUCCESS: "CASE_SEND_EMAIL_SUCCESS",
  CASE_SEND_EMAIL_FAILURE: "CASE_SEND_EMAIL_FAILURE",

  CASE_SAVE_EMAIL_TO_LOG_REQUEST: "CASE_SAVE_EMAIL_TO_LOG_REQUEST",
  CASE_SAVE_EMAIL_TO_LOG_SUCCESS: "CASE_SAVE_EMAIL_TO_LOG_SUCCESS",
  CASE_SAVE_EMAIL_TO_LOG_FAILURE: "CASE_SAVE_EMAIL_TO_LOG_FAILURE",

  CASE_UPDATE_DEADLINE_REQUEST: "CASE_UPDATE_DEADLINE_REQUEST",
  CASE_UPDATE_DEADLINE_SUCCESS: "CASE_UPDATE_DEADLINE_SUCCESS",
  CASE_UPDATE_DEADLINE_FAILURE: "CASE_UPDATE_DEADLINE_FAILURE",
  CASE_UPDATE_DEADLINE_FAILURE_RESET: "CASE_UPDATE_DEADLINE_FAILURE_RESET",

  CASE_APPLY_TEMPLATE_REQUEST: "CASE_APPLY_TEMPLATE_REQUEST",
  CASE_APPLY_TEMPLATE_SUCCESS: "CASE_APPLY_TEMPLATE_SUCCESS",
  CASE_APPLY_TEMPLATE_FAILURE: "CASE_APPLY_TEMPLATE_FAILURE",

  CASEACTIVITY_GET_REQUEST: "CASEACTIVITY_GET_REQUEST",
  CASEACTIVITY_GET_SUCCESS: "CASEACTIVITY_GET_SUCCESS",
  CASEACTIVITY_GET_FAILURE: "CASEACTIVITY_GET_FAILURE",

  CASEACTIVITY_ADD_REQUEST: "CASEACTIVITY_ADD_REQUEST",
  CASEACTIVITY_ADD_SUCCESS: "CASEACTIVITY_ADD_SUCCESS",
  CASEACTIVITY_ADD_FAILURE: "CASEACTIVITY_ADD_FAILURE",

  CASEACTIVITY_DELETENOTE_REQUEST: "CASEACTIVITY_DELETENOTE_REQUEST",
  CASEACTIVITY_DELETENOTE_SUCCESS: "CASEACTIVITY_DELETENOTE_SUCCESS",
  CASEACTIVITY_DELETENOTE_FAILURE: "CASEACTIVITY_DELETENOTE_FAILURE",

  CASEACTIVITY_DELETE_SCHEDULED_MAILS_REQUEST:
    "CASEACTIVITY_DELETE_SCHEDULED_MAILS_REQUEST",
  CASEACTIVITY_DELETE_SCHEDULED_MAILS_SUCCESS:
    "CASEACTIVITY_DELETE_SCHEDULED_MAILS_SUCCESS",
  CASEACTIVITY_DELETE_SCHEDULED_MAILS_FAILURE:
    "CASEACTIVITY_DELETE_SCHEDULED_MAILS_FAILURE",

  CASEACTIVITY_EMAILCONTENT_REQUEST: "CASEACTIVITY_EMAILCONTENT_REQUEST",
  CASEACTIVITY_EMAILCONTENT_SUCCESS: "CASEACTIVITY_EMAILCONTENT_SUCCESS",
  CASEACTIVITY_EMAILCONTENT_FAILURE: "CASEACTIVITY_EMAILCONTENT_FAILURE",

  CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_REQUEST:
    "CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_REQUEST",
  CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_SUCCESS:
    "CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_SUCCESS",
  CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_FAILURE:
    "CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_FAILURE",

  CASEACTIVITY_EMAILMARKUNREAD_REQUEST: "CASEACTIVITY_EMAILMARKUNREAD_REQUEST",
  CASEACTIVITY_EMAILMARKUNREAD_SUCCESS: "CASEACTIVITY_EMAILMARKUNREAD_SUCCESS",
  CASEACTIVITY_EMAILMARKUNREAD_FAILURE: "CASEACTIVITY_EMAILMARKUNREAD_FAILURE",

  CASEACTIVITY_CLEAR_STATE: "CASEACTIVITY_CLEAR_STATE",
  CASEACTIVITY_RESET: "CASEACTIVITY_RESET",

  CASEACTIVITY_TYPE_NOTE: "NOTE",
  CASEACTIVITY_TYPE_EMAIL_IN: "EMAIL_IN",
  CASEACTIVITY_TYPE_EMAIL_OUT: "EMAIL_OUT",
  CASEACTIVITY_TYPE_LINK_CLICKED: "LINK_CLICKED",
  CASEACTIVITY_TYPE_LOGIN: "LOGIN",
  CASEACTIVITY_TYPE_BILLING_ADDR_SET: "BILLING_ADDR_SET",
  CASEACTIVITY_TYPE_PAYMENT_SUCCEEDED: "PAYMENT_SUCCEEDED",
  CASEACTIVITY_TYPE_STATE_CHANGE: "STATE_CHANGE",
  CASEACTIVITY_TYPE_PAYMENT_FAILED: "PAYMENT_FAILED",
  CASEACTIVITY_TYPE_PAYMENT_INITIATED: "PAYMENT_INITIATED",
  CASEACTIVITY_TYPE_CASE_CREATED: "CASE_CREATED",
  CASEACTIVITY_TYPE_EMAIL_SCHEDULED: "EMAIL_SCHEDULED",
  CASEACTIVITY_TYPE_TRIGGERED: "TRIGGERED",
  CASEACTIVITY_TYPE_SCHEDULED: "SCHEDULED",
  CASEACTIVITY_TYPE_INVOICE_GENERATED: "INVOICE_GENERATED",

  CASE_ACTIVITY_STATE_SCHEDULED: "SCHEDULED",
  CASE_ACTIVITY_STATE_PAUSED: "PAUSED",
  CASE_ACTIVITY_STATE_SENT: "SENT",
  CASE_ACTIVITY_STATE_FAILED: "FAILED",

  CASE_FILTER_NONE: "none",
  CASE_FILTER_MAIL: "mail",
  CASE_FILTER_REMINDER: "remi",
  CASE_FILTER_UPLOADED_LICENSE: "license",
  CASE_FILTER_FAILED_EMAIL: "EMAIL_FAILED",
  CASE_FILTER_PAUSED: "PAUSED",
  CASE_FILTER_REQUIRE_ATTENTION: "REQUIRE_ATTENTION",
  CASE_FILTER_IDLE_CASES: "IDLE_CASES",
  CASE_FILTER_FAILED_PAYMENT: "FAILED_PAYMENT",

  // CASE STATE
  CASE_STATE_ACTIVE: "ACTIVE",
  CASE_STATE_VALID_LICENSE: "VALID_LICENSE",
  CASE_STATE_PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
  CASE_STATE_PAID: "PAID",
  CASE_STATE_CANCELLED: "CANCELLED",
  CASE_STATE_STANDBY: "STANDBY",

  CASE_TOGGLE_AUTOMATION_SUCCESS: "CASE_TOGGLE_AUTOMATION_SUCCESS",
  CASE_TOGGLE_AUTOMATION_FAILURE: "CASE_TOGGLE_AUTOMATION_FAILURE",
  CASE_TOGGLE_AUTOMATION_REQUEST: "CASE_TOGGLE_AUTOMATION_REQUEST",

  CASE_GENERATE_INVOICE_REQUEST: "GENERATE_INVOICE_REQUEST",
  CASE_GENERATE_INVOICE_FAILURE: "GENERATE_INVOICE_FAILURE",
  CASE_GENERATE_INVOICE_SUCCESS: "GENERATE_INVOICE_SUCCESS",

  CASE_DECREASE_FAILED: "CASE_DECREASE_FAILED",

  CASE_AUTOMATION_STATE_PAUSED: "PAUSED",
  CASE_AUTOMATION_STATE_SCHEDULED: "SCHEDULED",

  CASE_TAX_TYPE_VAT: "VAT",
  CASE_TAX_TYPE_GST: "GST",
  CASE_TAX_TYPE_SALES_TAX: "SALES_TAX",
  CASE_TAX_TYPE_NO_INDIRECT_TAX: "NO_INDIRECT_TAX",
  CASE_TAX_TYPE_REVERSE_CHARGE: "REVERSE_CHARGE",
};
