import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer/Footer";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import TosPopup from "@_components/popups/TosPopup/TosPopup";
import styles from "./Layout.module.css";
import { authService } from "@_services/auth.service";
import { getUserDataFromJwt } from "@_utils/helpers";
import { emailActions } from "@_actions/email_flows.actions";
import { subscriptionActions } from "@_actions/subscription.actions";
import { userActions } from "@_actions/user.actions";
import { invoiceTemplateActions } from "@_actions/invoiceTemplate.actions";

function Layout(props) {
  const dispatch = useDispatch();

  const [openTosPopup, setOpenTosPopup] = useState(false);
  const [tosData, setTosData] = useState(null);

  const authReducer = useSelector((state) => state.authReducer);

  useEffect(checkTosStatus, []);
  useEffect(() => {
    dispatch(userActions.getSignature());
    dispatch(invoiceTemplateActions.getInvoiceTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (authReducer.user) {
      dispatch(emailActions.getEmail());
    } else return;
  }, [dispatch, authReducer.user]);

  useEffect(() => {
    if (authReducer.user) {
      dispatch(subscriptionActions.getSubscriptionConfig());
    } else return;
  }, [dispatch, authReducer.user]);

  function checkTosStatus() {
    if (getUserDataFromJwt().user_role === "ADMIN") {
      authService.getTos().then(
        (results) => {
          if (!results.current_tos_accepted) {
            // If the latest TOS is not accepted
            setTosData(results);
            setOpenTosPopup(true);
          }
        },
        (error) => {
          // TODO alert user
        }
      );
    }
  }

  function tosClosed() {
    setOpenTosPopup(false);
  }
  return (
    <div className={styles.root}>
      <Header loggedIn={authReducer.user} />
      {authReducer.user && window.location.pathname === "/cases" && <Sidebar />}
      <div
        className={
          authReducer.user && window.location.pathname === "/cases"
            ? styles.content
            : styles.content_no_user
        }
      >
        {props.children}
      </div>
      <Footer />
      <TosPopup open={openTosPopup} close={tosClosed} data={tosData} />
    </div>
  );
}

export default Layout;
