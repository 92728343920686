import { caseConstants } from "@_constants/case.constants";
import { invoiceTemplateConstants } from "@_constants/invoiceTemplate.constants";

const initialState = {
  activities: [],
  loading: false,
  message: "",
  status: {
    CASEACTIVITY_GET: "",
    CASEACTIVITY_ADD: "",
    CASEACTIVITY_DELETE: "",
    EMAIL_CONTENT: "",
    EMAIL_UNREAD: "",
    INVOICE_CANCEL: "",
  },
};

export function caseActivityReducer(state = initialState, action) {
  const createActivity = (activity) => {
    return {
      user_name: JSON.parse(localStorage.getItem("jwtData")).username,
      created: activity.created,
      id: activity.case_activity_id,
      type: activity.type,
      content: activity.content,
    };
  };
  switch (action.type) {
    // CASE_CLEAR_STATE_MESSGAE
    case caseConstants.CASEACTIVITY_CLEAR_STATE:
      state = {
        ...state,
        status: { ...state.status, [action.state]: "" },
      };
      break;
    case caseConstants.CASEACTIVITY_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASEACTIVITY_GET: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_GET_SUCCESS:
      state = {
        ...state,
        activities: action.activities,
        loading: false,
        message: "",
        status: { ...state.status, CASEACTIVITY_GET: "success" },
      };
      break;
    case caseConstants.CASEACTIVITY_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASEACTIVITY_GET: "failure" },
      };
      break;
    case caseConstants.CASEACTIVITY_ADD_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASEACTIVITY_ADD: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_ADD_SUCCESS:
      state = {
        ...state,
        activities: [createActivity(action.activity), ...state.activities],
        loading: false,
        message: "",
        status: { ...state.status, CASEACTIVITY_ADD: "success" },
      };
      break;
    case caseConstants.CASEACTIVITY_ADD_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASEACTIVITY_ADD: "failure" },
      };
      break;
    case caseConstants.CASEACTIVITY_DELETENOTE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASEACTIVITY_DELETE: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_DELETENOTE_SUCCESS:
      state = {
        ...state,
        activities: state.activities.filter((x) => x.id !== action.activity_id),
        loading: false,
        message: "",
        status: { ...state.status, CASEACTIVITY_DELETE: "success" },
      };
      break;
    case caseConstants.CASEACTIVITY_DELETENOTE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASEACTIVITY_DELETE: "failure" },
      };
      break;

    case caseConstants.CASEACTIVITY_DELETE_SCHEDULED_MAILS_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASEACTIVITY_DELETE: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_DELETE_SCHEDULED_MAILS_SUCCESS:
      state = {
        ...state,
        activities: state.activities.filter((x) => x.id !== action.activity_id),
        loading: false,
        message: "",
        status: { ...state.status, CASEACTIVITY_DELETE: "success" },
      };
      break;
    case caseConstants.CASEACTIVITY_DELETE_SCHEDULED_MAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASEACTIVITY_DELETE: "failure" },
      };
      break;

    case caseConstants.CASEACTIVITY_EMAILCONTENT_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_CONTENT: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_EMAILCONTENT_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, EMAIL_CONTENT: "" },
        activities: state.activities.map((item, i) => {
          if (
            action.data.content &&
            item.content &&
            item.content.email_id &&
            item.content.email_id === action.data.content.id
          ) {
            return {
              ...item,
              content: {
                ...item.content,
                mail_content: action.data.content,
                unread: 0,
              },
            };
          } else {
            return item;
          }
        }),
      };

      break;
    case caseConstants.CASEACTIVITY_EMAILCONTENT_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_CONTENT: action.error },
      };
      break;

    case caseConstants.CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_CONTENT: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, EMAIL_TEMPLATE_CONTENT: "" },
        activities: state.activities.map((item, i) => {
          if (
            action.data.content &&
            item.content &&
            item.content.source_email_template_id &&
            item.content.source_email_template_id ===
              action.data.email_template_id
          ) {
            return {
              ...item,
              content: {
                ...item.content,
                mail_content: action.data.content,
                unread: 0,
              },
            };
          } else {
            return item;
          }
        }),
      };
      break;
    case caseConstants.CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_TEMPLATE_CONTENT: action.error },
      };
      break;

    case caseConstants.CASEACTIVITY_EMAILMARKUNREAD_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_UNREAD: "request" },
      };
      break;
    case caseConstants.CASEACTIVITY_EMAILMARKUNREAD_SUCCESS:
      if (action.data.case_id === null) {
        state = {
          ...state,
          status: { ...state.status, EMAIL_UNREAD: "success" },
        };
      } else
        state = {
          ...state,
          status: { ...state.status, EMAIL_UNREAD: "success" },
          activities: state.activities.map((item, i) => {
            if (
              item.content &&
              item.content.email_id &&
              item.content.email_id === action.data.email_id
            ) {
              return {
                ...item,
                content: {
                  ...item.content,
                  mail_content: null,
                  unread: 1,
                },
              };
            } else {
              return item;
            }
          }),
        };
      break;
    case caseConstants.CASEACTIVITY_EMAILMARKUNREAD_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_UNREAD: "failure" },
      };
      break;
    case caseConstants.CASEACTIVITY_RESET:
      state = {
        ...initialState,
      };
      break;

    case invoiceTemplateConstants.INVOICE_CANCEL_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, INVOICE_CANCEL: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_CANCEL_SUCCESS:
      const cancellationInvoice = action.data;
      const activitiesWithCancelledInvoice = state.activities.map(
        (activity) => {
          if (activity.content?.invoice_id === action.cancelledInvoiceId) {
            activity.content.type =
              invoiceTemplateConstants.INVOICE_TYPE_CANCELLED;
            activity.content.cancellation_invoice_id = cancellationInvoice.id;
          }
          return activity;
        }
      );

      state = {
        ...state,
        activities: [cancellationInvoice, ...activitiesWithCancelledInvoice],
        loading: false,
        message: "",
        status: { ...state.status, INVOICE_CANCEL: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_CANCEL_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, INVOICE_CANCEL: action.error },
      };
      break;
    default:
      break;
  }

  return state;
}
