import { emailConstants } from "@_constants/email_flows.constants";
import { emailService } from "@_services/email_flows.service";

export const emailActions = {
  getEmail,
  addEmail,
  updateEmail,
  deleteEmail,
  selectEmail,
  clearStatusMessage,
  addFlowConfig,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: emailConstants.EMAIL_FLOW_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function getEmail(id) {
  return (dispatch) => {
    dispatch(request());
    emailService.getEmail(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailConstants.EMAIL_FLOW_GET_REQUEST };
  }
  function success(data) {
    return { type: emailConstants.EMAIL_FLOW_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailConstants.EMAIL_FLOW_GET_FAILURE, error };
  }
}

function addEmail(data) {
  return (dispatch) => {
    dispatch(request());
    emailService.addEmail(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailConstants.EMAIL_FLOW_POST_REQUEST };
  }
  function success(data) {
    return { type: emailConstants.EMAIL_FLOW_POST_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailConstants.EMAIL_FLOW_POST_FAILURE, error };
  }
}

function updateEmail(flow_id, data) {
  return (dispatch) => {
    dispatch(request());
    emailService.updateEmail(flow_id, data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailConstants.EMAIL_FLOW_UPDATE_REQUEST };
  }
  function success(id, data) {
    return { type: emailConstants.EMAIL_FLOW_UPDATE_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: emailConstants.EMAIL_FLOW_UPDATE_FAILURE, error };
  }
}

function deleteEmail(flow_id) {
  return (dispatch) => {
    dispatch(request());
    emailService.deleteEmail(flow_id).then(
      (data) => {
        dispatch(success(flow_id));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailConstants.EMAIL_FLOW_DELETE_REQUEST };
  }
  function success(id, data) {
    return { type: emailConstants.EMAIL_FLOW_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailConstants.EMAIL_FLOW_DELETE_FAILURE, error };
  }
}

/**
 *
 * @param {string} data id of email flow
 * @returns
 */
function selectEmail(data) {
  return (dispatch) => {
    dispatch({ type: emailConstants.SELECT_EMAIL_FLOW, data });
  };
}

function addFlowConfig(id, config) {
  return (dispatch) => {
    dispatch({ type: emailConstants.ADD_FLOW_CONFIG, id, config });
  };
}
