import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  catchError,
  formatDatetime,
  isComponentDisabled,
} from "@_utils/helpers";
import styles from "./LicensePopup.module.css";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import FileIcon from "@material-ui/icons/FileCopy";
import { LicenseConstants } from "@_constants/license.constants";
import { caseActivityActions } from "@_actions/caseActivity.actions";

const componetTag = "license_popup";
function LicensePopup(props) {
  const dispatch = useDispatch();
  const { data, caseId, resumeAutomation } = props;
  const inputDisabled = isComponentDisabled(componetTag, data.state);
  const caseReducer = useSelector((state) => state.caseReducer);

  const [findings, setFindings] = useState([]);
  const [licencePopupSubmitInProgress, setLicencePopupSubmitInProgress] =
    useState(false);

  useEffect(() => {
    // We have to deep copy the array into state
    // otherwise changes on findings (with setFindings)
    // will reference the data.findings
    setFindings(JSON.parse(JSON.stringify(data.findings)));
  }, [data]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    let newFindings = [...findings];
    newFindings[index].license.state = value;
    setFindings(newFindings);
  };

  function actionClicked() {
    findings &&
      findings.map((finding, index) => {
        if (finding.license) {
          setLicencePopupSubmitInProgress(true);
          if (finding.license.state === "DELETE") {
            dispatch(caseActions.deleteCaseLicense(caseId, index, finding.id));
          } else {
            dispatch(
              caseActions.updateCaseLicenseState(caseId, {
                state: finding.license.state,
                id: finding.id,
              })
            );
          }
          return true;
        } else {
          return false;
        }
      });
  }

  useEffect(() => {
    if (
      (licencePopupSubmitInProgress &&
        caseReducer.status["CASE_UPDATE_LICENSE_STATE"] === "success") ||
      caseReducer.status["CASE_DELETE_LICENSE"] === "success"
    ) {
      setLicencePopupSubmitInProgress(false);
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_LICENSE_STATE"));
      dispatch(caseActions.clearStateMessage("CASE_DELETE_LICENSE"));
      //update license was successfull, reload cases, the case and activities
      dispatch(casesActions.getCases());
      dispatch(casesActions.getCasesCount());
      dispatch(caseActions.getCaseWithChargeDetails(caseId, data.overview));
      dispatch(caseActivityActions.getCaseActivities(caseId));
      if (
        findings.some(
          (finding) => finding.license?.state === LicenseConstants.STATE_INVALID
        )
      ) {
        resumeAutomation();
      }
      props.close();
    } else if (
      (licencePopupSubmitInProgress &&
        catchError(caseReducer.status["CASE_UPDATE_LICENSE_STATE"])) ||
      catchError(caseReducer.status["CASE_DELETE_LICENSE"])
    ) {
      setLicencePopupSubmitInProgress(false);
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_LICENSE_STATE"));
      dispatch(caseActions.clearStateMessage("CASE_DELETE_LICENSE"));
      if (catchError(caseReducer.status["CASE_UPDATE_LICENSE_STATE"]))
        window.alert("Update license(s) failed.");
    }
  }, [
    caseId,
    caseReducer.status,
    caseReducer.trigger_sending_email_failed,
    data.overview,
    dispatch,
    findings,
    licencePopupSubmitInProgress,
    props,
    resumeAutomation,
  ]);

  function cancelAction() {
    props.close();
    return;
  }

  return (
    <DialogPopup
      title="Uploaded Licence"
      open={props.open}
      onClose={() => {
        props.close();
      }}
    >
      <div className={styles.content_container}>
        <div className={styles.findings_container}>
          {findings &&
            findings.map((finding, index) => {
              if (finding.license)
                return (
                  <div className={styles.finding_container} key={index}>
                    <a
                      className={styles.license_link}
                      href={finding.license.license_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={styles.finging_image_container}>
                        <FileIcon />
                      </div>
                    </a>
                    <div className={styles.finging_details_container}>
                      <a
                        className={styles.finding_link}
                        href={finding.license.license_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {finding.license.filename}
                      </a>
                      <div className={styles.finding_date}>
                        {formatDatetime(
                          finding.license.date_uploaded,
                          "medium"
                        )}
                      </div>
                      {finding.license?.note && (
                        <div className={styles.finding_note}>
                          <b>NOTE: </b> {finding.license.note}
                        </div>
                      )}
                    </div>
                    <div className={styles.finging_validity_container}>
                      <RadioGroup
                        name="licenseValidity"
                        value={finding.license.state}
                        onChange={(e) => handleChange(e, index)}
                      >
                        <FormControlLabel
                          value="VALID"
                          control={<Radio />}
                          label="Valid"
                          disabled={inputDisabled}
                        />
                        <FormControlLabel
                          value="INVALID"
                          control={<Radio />}
                          label="Invalid"
                          disabled={inputDisabled}
                        />
                        <FormControlLabel
                          value="DELETE"
                          control={<Radio />}
                          label="Delete"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                );
              else return null;
            })}
        </div>
      </div>
      <div className="action_container">
        <>
          <Button className="btn_cancel" onClick={cancelAction}>
            Cancel
          </Button>
          <Button className="btn_confirm" onClick={actionClicked}>
            Confirm
          </Button>
        </>
      </div>
    </DialogPopup>
  );
}

export default LicensePopup;
