import { authConstants } from "@_constants/auth.constants";

let user = localStorage.getItem("user");
const initialState = {
  authenticated: user ? true : false,
  loading: false,
  user: user,
  status: {
    LOGIN: "",
  },
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = { ...state, loading: true, message: "" };
      break;

    case authConstants.LOGIN_SUCCESS:
      console.log(action.user);
      state = {
        ...state,
        loading: false,
        message: "",
        user: action.user,
        status: { ...state.status, LOGIN: "success" },
      };
      break;

    case authConstants.LOGIN_FAILURE:
      state = { ...state, loading: false, message: action.error, user: null };
      break;

    case authConstants.LOGOUT:
      state = { ...state, loading: false, message: "", user: null };
      break;

    case authConstants.SIGNED_UP:
      state = {
        ...state,
        user: action.data,
      };
      break;

    default:
      break;
  }

  return state;
}
