import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import DateInput from "@_components/DateInput/DateInput";
import {
  getUserDataFromJwt,
  isComponentDisabled,
  isNull,
} from "@_utils/helpers";
import styles from "./ClaimPopup.module.css";
import Switch from "@material-ui/core/Switch";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import DeleteIcon from "@material-ui/icons/RemoveCircle";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import CountryAutocompleteList from "@_configs/CountryAutocompleteList.config";
import TaxTypes from "@_configs/TaxType.config";
import CurrencyList from "@_configs/CurrencyList.config";
import { caseConstants } from "@_constants/case.constants";
import { FormControlLabel } from "@material-ui/core";
import Scrollbar from "react-perfect-scrollbar-z";

const componetTag = "claim_popup";
function ClaimPopup(props) {
  const dispatch = useDispatch();
  const jwtData = getUserDataFromJwt();
  const { data, caseId } = props;
  const inputDisabled = isComponentDisabled(componetTag, data.state);
  const invoiceReducer = useSelector((state) => state.invoiceTemplateReducer);
  const invoiceTemplates = invoiceReducer.templates;

  const [inputs, setInputs] = useState({});
  const [findings, setFindings] = useState(data.findings);
  const [additionalFees, setAdditionalFees] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isTaxable, setIsTaxable] = useState(false);

  useEffect(() => {
    if (!isClicked) {
      setFindings(data.findings);
      setAdditionalFees(data.claim_details.additional_fees || []);
      setInputs({
        currency: data.claim_details.currency,
        indirect_tax_type: data.claim_details.indirect_tax_type || "",
        indirect_tax_percentage: data.claim_details.indirect_tax_percentage,
        country: data.country,
        settlement_offer_date: data.claim_details.settlement_offer_date || "",
        settlement_offer_percentage:
          data.claim_details.settlement_offer_percentage || "",
        invoice_template_id: data.invoice_template_id,
        vat_id_required: data.vat_id_required,
        allow_prepayment_invoicing: data.allow_prepayment_invoicing,
      });
    }
  }, [data, isClicked]);

  function actionClicked() {
    //TODO: come up with better validation than if checks

    // in case the state of the case is paid only the invoice
    // template can be changed, so only update that
    if (data.state === "PAID") {
      if (inputs.indirect_tax_percentage === null)
        delete inputs.indirect_tax_percentage;
      dispatch(caseActions.updateCaseClaimDetails(caseId, inputs));
    } else {
      // non paid state-> update everything

      if (
        (!isNull(inputs.settlement_offer_percentage) &&
          inputs.settlement_offer_percentage < 0) ||
        (inputs.settlement_offer_percentage <= 0 &&
          inputs.settlement_offer_date) ||
        (inputs.settlement_offer_percentage > 0 &&
          !inputs.settlement_offer_date)
      ) {
        return;
      }
      setIsClicked(true);
      if (
        inputs.indirect_tax_type !==
          caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX &&
        inputs.indirect_tax_type !==
          caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE &&
        isNull(inputs.indirect_tax_percentage)
      )
        return;
      if (isNull(inputs.country)) return;
      if (isNull(inputs.currency)) return;

      if (
        inputs.indirect_tax_type ===
          caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX ||
        inputs.indirect_tax_type === caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE
      ) {
        delete inputs.indirect_tax_percentage;
      }

      if (
        inputs.settlement_offer_date &&
        moment(inputs.settlement_offer_date).format("DD/MM/yyyy") ===
          "Invalid date"
      ) {
        alert("Invalid settlement offer date!");
        return;
      }
      if (inputs.indirect_tax_percentage <= 0) {
        alert("VAT Percentage must be larger than 0");
        return;
      }

      if (inputs.settlement_offer_percentage < 0) {
        alert("Settelment offer percentage cant be a negative value");
        return;
      }

      let zeroPriceFinding = false;
      findings &&
        findings.map((finding, index) => {
          if (finding.price <= 0 || !finding.price) {
            zeroPriceFinding = true;
          }
          return finding;
        });
      if (zeroPriceFinding) {
        return;
      }

      let additionalFeeNoName = false;
      additionalFees.map((fee, index) => {
        if (!fee.name || fee.name.trim() === "") {
          additionalFeeNoName = true;
        }
        return fee;
      });
      if (additionalFeeNoName) return;

      let additionalFeeNoPerc = false;
      additionalFees.map((fee, index) => {
        if (fee.percentage < 0 || !fee.percentage) {
          additionalFeeNoPerc = true;
        }
        return fee;
      });
      if (additionalFeeNoPerc) return;

      var reducedInputs = Object.fromEntries(
        Object.entries(inputs).filter(([_, v]) => v !== null && v !== "")
      );

      dispatch(caseActions.updateCaseCountry(caseId, reducedInputs));
      dispatch(caseActions.updateCaseClaimDetails(caseId, reducedInputs));

      if (additionalFees.length > 0) {
        dispatch(caseActions.addCaseAdditionalFees(caseId, additionalFees));
      } else {
        dispatch(caseActions.addCaseAdditionalFees(caseId, []));
      }

      findings &&
        findings.map((finding, index) => {
          let findingData = { ...finding, price: parseFloat(finding.price) };
          dispatch(
            caseActions.updateCaseFindingPrice(props.caseId, findingData)
          );
          return true;
        });
    }

    setTimeout(() => {
      dispatch(casesActions.getCases());
      setTimeout(() => {
        dispatch(caseActions.getCaseWithChargeDetails(caseId, data.overview));
        // TODO: snackbar and error handling before closing the popup

        props.close();
      }, 0);
    }, 100);
  }

  function addAdditionalFees() {
    let newAdditionalFee = [...additionalFees];
    const emptyAdditionalFee = {
      name: "",
      percentage: 0,
    };
    newAdditionalFee.unshift(emptyAdditionalFee);
    setAdditionalFees(newAdditionalFee);
  }

  function handleDeleteAdditionalFee(e, index) {
    let newAdditionalFee = [...additionalFees];
    newAdditionalFee.splice(index, 1);
    setAdditionalFees(newAdditionalFee);
  }

  function handleInputChange(e) {
    const { id, name } = e.target;
    let value = e.target.value;
    if (
      id === "indirect_tax_percentage" ||
      id === "settlement_offer_percentage"
    ) {
      value = value && parseFloat(value);
      if (id === "settlement_offer_percentage" && value > 100) value = 100;
    }
    if (name === "indirect_tax_type" || id === "indirect_tax_type")
      setInputs((inputs) => ({
        ...inputs,
        invoice_template_id: "",
        allow_prepayment_invoicing: false,
      }));
    if (name === "invoice_template_id" && !value) {
      setInputs((inputs) => ({
        ...inputs,
        allow_prepayment_invoicing: false,
      }));
    }
    setInputs((inputs) => ({
      ...inputs,
      [id || name]: value,
    }));
  }

  function handleDateChange(date) {
    setInputs({ ...inputs, settlement_offer_date: date });
  }

  function handleFindingPriceInputChange(e, index) {
    let newFindings = [...findings];
    newFindings[index].price = parseFloat(e.target.value);
    setFindings(newFindings);
  }

  function handleAdditionalFeesInputChange(e, index) {
    let newAdditionalFee = [...additionalFees];
    newAdditionalFee[index][e.target.name] =
      e.target.name === "percentage"
        ? parseFloat(e.target.value)
        : e.target.value;
    setAdditionalFees(newAdditionalFee);
  }

  useEffect(() => {
    if (
      inputs.indirect_tax_type &&
      inputs.indirect_tax_type !==
        caseConstants.CASE_TAX_TYPE_NO_INDIRECT_TAX &&
      inputs.indirect_tax_type !== caseConstants.CASE_TAX_TYPE_REVERSE_CHARGE
    ) {
      setIsTaxable(true);
    } else setIsTaxable(false);
  }, [inputs.indirect_tax_type]);

  const handleCancel = () => {
    setInputs({
      currency: data.claim_details.currency,
      indirect_tax_type: data.claim_details.indirect_tax_type || "",
      indirect_tax_percentage: data.claim_details.indirect_tax_percentage,
      country: data.country,
      settlement_offer_date: data.claim_details.settlement_offer_date || "",
      settlement_offer_percentage:
        data.claim_details.settlement_offer_percentage || "",
      invoice_template_id: data.invoice_template_id || "",
      vat_id_required: data.vat_id_required || false,
      allow_prepayment_invoicing: data.allow_prepayment_invoicing || false,
    });
    props.close();
  };

  return (
    <DialogPopup
      title="Licensing offer"
      open={props.open}
      onClose={() => handleCancel()}
    >
      <Scrollbar maxHeight="74vh" options={{ suppressScrollX: true }}>
        <div className={styles.content_container}>
          <TextfieldInput
            select
            label="Currency"
            id="currency"
            name="currency"
            value={inputs.currency}
            onChange={handleInputChange}
            disabled={inputDisabled}
            error={isClicked && isNull(inputs.currency)}
            required
          >
            {CurrencyList.map((currency) => (
              <MenuItem key={currency.id} value={currency.id}>
                {`${currency.label} (${currency.id})`}
              </MenuItem>
            ))}
          </TextfieldInput>

          <div className={styles.findings_container}>
            {findings &&
              findings.map((finding, index) => (
                <div className={styles.finding_container} key={index}>
                  <div className={styles.finging_image_container}>
                    <img
                      className={styles.finging_image}
                      src={finding.original_image.original_image_url}
                      alt={"Finding result"}
                    />
                  </div>
                  <div className={styles.finging_details_container}>
                    <p className={styles.finding_id}>
                      {finding.original_image.name}
                    </p>
                  </div>
                  <div className={styles.finging_price_container}>
                    {finding.license && finding.license.state === "VALID" ? (
                      <p className={styles.valid_note}>
                        Valid license provided
                      </p>
                    ) : (
                      <TextfieldInput
                        label="License Fee"
                        name={"finding" + index}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        id={"finding" + index}
                        defaultValue={finding.price}
                        onChange={(e) =>
                          handleFindingPriceInputChange(e, index)
                        }
                        disabled={inputDisabled}
                        required
                        error={
                          isClicked && (finding.price <= 0 || !finding.price)
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
            {(!findings || findings.length <= 0) && (
              <div className={styles.no_findings}>
                Please add image uses and evidence in order to set up your
                licensing offer.
              </div>
            )}
          </div>

          {!inputDisabled && (
            <>
              <hr className={styles.claim_hr} />
              <div className={styles.add_fee_container}>
                <Button
                  className={styles.btn_additional_fees}
                  onClick={addAdditionalFees}
                  disabled={inputDisabled}
                >
                  Add Additional Fees
                </Button>
              </div>
            </>
          )}

          <div className={styles.additional_fees_container}>
            {additionalFees.map((fee, index) => (
              <div className={styles.additional_fee_container} key={index}>
                {!inputDisabled && (
                  <div className={styles.btn_delete_container}>
                    <DeleteIcon
                      onClick={(e) => handleDeleteAdditionalFee(e, index)}
                    />
                  </div>
                )}
                <TextfieldInput
                  className={styles.first_input}
                  label="Additional Fee Name"
                  id={"name" + index}
                  name={"name"}
                  value={fee.name}
                  onChange={(e) => handleAdditionalFeesInputChange(e, index)}
                  disabled={inputDisabled}
                  error={!fee.name || fee.name.trim() === ""}
                />
                <TextfieldInput
                  label="Percentage %"
                  id={"percentage" + index}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  name={"percentage"}
                  value={fee.percentage}
                  onChange={(e) => handleAdditionalFeesInputChange(e, index)}
                  disabled={inputDisabled}
                  error={fee.percentage < 0 || !fee.percentage}
                />
              </div>
            ))}
          </div>

          <hr className={styles.claim_hr} />
          <div className={styles.other_fees_container}>
            <div className={styles.settelment_container}>
              <TextfieldInput
                className={styles.first_input}
                label="Discount %"
                id="settlement_offer_percentage"
                name="settlement_offer_percentage"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={inputs.settlement_offer_percentage}
                onChange={handleInputChange}
                disabled={inputDisabled}
                error={
                  (!isNull(inputs.settlement_offer_percentage) &&
                    inputs.settlement_offer_percentage < 0) ||
                  (inputs.settlement_offer_percentage <= 0 &&
                    inputs.settlement_offer_date)
                }
              />
              <DateInput
                label="Discount Deadline"
                id="settlement_offer_date"
                name="settlement_offer_date"
                value={inputs.settlement_offer_date}
                onChange={(date) => handleDateChange(date)}
                error={
                  inputs.settlement_offer_percentage > 0 &&
                  !inputs.settlement_offer_date
                }
                disabled={inputDisabled}
                disablePast
              />
            </div>
            <div className={styles.vat_container}>
              <TextfieldInput
                className={styles.input_small}
                select
                label="Country"
                id="country"
                name="country"
                value={inputs.country}
                onChange={handleInputChange}
                disabled={inputDisabled}
                error={isClicked && isNull(inputs.country)}
                required
              >
                {CountryAutocompleteList.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {`${country.label} (${country.id})`}
                  </MenuItem>
                ))}
              </TextfieldInput>
              <TextfieldInput
                className={styles.input_small}
                select
                label="Tax"
                id="indirect_tax_type"
                name="indirect_tax_type"
                value={inputs.indirect_tax_type}
                onChange={handleInputChange}
                disabled={inputDisabled}
                error={isClicked && isNull(inputs.indirect_tax_type)}
                required
              >
                {TaxTypes.map((TaxType) => (
                  <MenuItem key={TaxType.id} value={TaxType.id}>
                    {`${TaxType.label} `}
                  </MenuItem>
                ))}
              </TextfieldInput>

              <TextfieldInput
                className={styles.input_small}
                label="Tax Percentage %"
                id="indirect_tax_percentage"
                name="indirect_tax_percentage"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={isTaxable ? inputs.indirect_tax_percentage : ""}
                onChange={handleInputChange}
                disabled={inputDisabled || !isTaxable}
                style={!isTaxable ? { visibility: "hidden" } : {}}
                error={
                  (isClicked && isNull(inputs.indirect_tax_percentage)) ||
                  (isClicked && inputs.indirect_tax_percentage <= 0)
                }
                required
              />
            </div>
            {jwtData.subscription.toLowerCase() !== "lite" && (
              <>
                <div className={styles.vat_container}>
                  <TextfieldInput
                    select
                    label="Invoice Template"
                    id="invoice_template_id"
                    name="invoice_template_id"
                    value={inputs.invoice_template_id}
                    onChange={handleInputChange}
                    disabled={!inputs.indirect_tax_type}
                  >
                    <MenuItem value="">None</MenuItem>
                    {invoiceTemplates
                      .filter(
                        (invoice) =>
                          invoice.tax_type === inputs.indirect_tax_type
                      )
                      .map((invoice) => (
                        <MenuItem key={invoice.id} value={invoice.id}>
                          {`${invoice.name}`}
                        </MenuItem>
                      ))}
                  </TextfieldInput>
                  <FormControlLabel
                    required
                    control={
                      <Switch
                        color="primary"
                        className={styles.taxable_switch}
                        checked={inputs.vat_id_required}
                        onChange={(e) =>
                          setInputs((inputs) => ({
                            ...inputs,
                            vat_id_required: !inputs.vat_id_required,
                          }))
                        }
                        id={"vat_id_required"}
                        name="vat_id_required"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Website owner's VAT ID required"
                  />
                </div>
                <FormControlLabel
                  style={!inputs.invoice_template_id ? { display: "none" } : {}}
                  required
                  control={
                    <Switch
                      color="primary"
                      className={styles.taxable_switch}
                      checked={
                        inputs.allow_prepayment_invoicing &&
                        inputs.invoice_template_id
                      }
                      onChange={(e) =>
                        setInputs((inputs) => ({
                          ...inputs,
                          allow_prepayment_invoicing:
                            !inputs.allow_prepayment_invoicing,
                        }))
                      }
                      id={"allow_prepayment_invoicing"}
                      name="allow_prepayment_invoicing"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      disabled={!inputs.invoice_template_id}
                    />
                  }
                  label="Allow invoice generation before payment"
                />
              </>
            )}
          </div>
        </div>
      </Scrollbar>
      <div className="action_container">
        <>
          <Button className="btn_cancel" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button className="btn_confirm" onClick={actionClicked}>
            Confirm
          </Button>
        </>
      </div>
    </DialogPopup>
  );
}

export default ClaimPopup;
