import React from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import { authService } from "@_services/auth.service";
import { formatDatetime } from "@_utils/helpers";
import styles from "./TosPopup.module.css";

function TosPopup(props) {
  const { data } = props;

  const actionClicked = () => {
    authService.updateTos(data.version).then(
      (results) => {
        props.close();
      },
      (error) => {
        // TODO alert user
      }
    );
  };

  return (
    <DialogPopup
      title={"Terms of Service " + (data && data.version)}
      open={props.open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") props.close(e);
      }}
      size={"large"}
      disableEscapeKeyDown={true}
    >
      {data && data.must_accept ? (
        <div className={styles.tos_title}>
          You have to accept these terms of service now in order to stop account
          suspension.
        </div>
      ) : (
        <div className={styles.tos_title}>
          These TOS have to be accepted till{" "}
          <b>{data && formatDatetime(data.must_accept_date)}</b>, otherwise your
          account may be suspended.
        </div>
      )}

      <div className={styles.tosContentContainer}>
        {data && (
          <iframe
            className={styles.iframe}
            srcDoc={data.content}
            title="TermsOfService"
          ></iframe>
        )}
      </div>
      <div className="action_container">
        {data && !data.must_accept && (
          <Button className="btn_cancel" onClick={() => props.close()}>
            Remind me later
          </Button>
        )}
        <Button className="btn_confirm" onClick={actionClicked}>
          Accept
        </Button>
      </div>
    </DialogPopup>
  );
}

export default TosPopup;
