import { caseConstants } from "@_constants/case.constants";
import { LicenseConstants } from "@_constants/license.constants";

const initialState = {
  case: {},
  loading: false,
  message: "",
  trigger_sending_email_failed: false,
  status: {
    CASE_GET: "",
    CASE_UPDATE_OPPONENT_DETAILS: "",
    CASE_UPDATE_LOG: "",
    CASE_ADD_LOG: "",
    CASE_UPDATE_IMPRINT: "",
    CASE_UPDATE_FINDING_IMAGE_DATA: "",
    CASE_UPDATE_FINDING_PRICE: "",
    CASE_UPDATE_CLAIM_DETAILS: "",
    CASE_GET_CHARGE_DETAILS: "",
    CASE_UPDATE_STATE_ACTIVATE: "",
    CASE_UPDATE_STATE_CANCEL: "",
    CASE_UPDATE_STATE_RESET: "",
    CASE_UPDATE_COUNTRY: "",
    CASE_ADD_ADDITIONAL_FEES: "",
    CASE_UPDATE_LICENSE_STATE: "",
    CASE_DELETE_FINDING: "",
    CASE_DELETE_LICENSE: "",
    CASE_DELETE_CALLBACK_REQUEST: "",
    CASE_ADD_FINDING: "",
    CASE_VALIDATE_FINDING: "",
    CASE_ADD_FLOW: "",
    CASE_ADD_TRANSLATION: "",
    CASE_SEND_EMAIL: "",
    CASE_SAVE_EMAIL_TO_LOG: "",
    CASE_TOGGLE_AUTOMATION: "",
    CASE_GENERATE_INVOICE: "",
  },
};

export function caseReducer(state = initialState, action) {
  switch (action.type) {
    // CASE_CLEAR_STATE_MESSGAE
    case caseConstants.CASE_CLEAR_STATE_MESSGAE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // CASE_GET_REQUEST
    case caseConstants.CASE_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_GET: "request" },
      };
      break;
    case caseConstants.CASE_GET_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_GET: "success" },
        case: {
          ...state.case,
          [action.caseDetails.id]: {
            ...action.caseDetails,
          },
        },
      };
      break;
    case caseConstants.CASE_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_GET: action.error },
      };
      break;

    // CASE_UPDATE_OPPONENT_DETAILS_REQUEST
    case caseConstants.CASE_UPDATE_OPPONENT_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_OPPONENT_DETAILS: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_OPPONENT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_OPPONENT_DETAILS: "success" },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            opponent_contact_details: action.data,
          },
        },
      };
      break;
    case caseConstants.CASE_UPDATE_OPPONENT_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_OPPONENT_DETAILS: action.error },
      };
      break;

    // CASE_UPDATE_LOG_REQUEST
    case caseConstants.CASE_UPDATE_LOG_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_LOG: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_LOG_SUCCESS:
      const newCaseLog = state.case[action.id].case_log;
      newCaseLog[newCaseLog.findIndex((x) => x.id === action.logId)] =
        action.data;
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_LOG: "success" },
        case: {
          ...state.case,
          [action.id]: { ...state.case[action.id], case_log: newCaseLog },
        },
      };
      break;
    case caseConstants.CASE_UPDATE_LOG_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_LOG: action.error },
      };
      break;

    // CASE_DELETE_CALLBACK_REQUEST_REQUEST
    case caseConstants.CASE_DELETE_CALLBACK_REQUEST_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_DELETE_CALLBACK_REQUEST: "request" },
      };
      break;
    case caseConstants.CASE_DELETE_CALLBACK_REQUEST_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_DELETE_CALLBACK_REQUEST: "success" },
        case: {
          ...state.case,
          [action.id]: { ...state.case[action.id], callback_request: null },
        },
      };
      break;
    case caseConstants.CASE_DELETE_CALLBACK_REQUEST_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_DELETE_CALLBACK_REQUEST: action.error },
      };
      break;

    // CASE_ADD_LOG_REQUEST
    case caseConstants.CASE_ADD_LOG_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_ADD_LOG: "request" },
      };
      break;
    case caseConstants.CASE_ADD_LOG_SUCCESS:
      const newCaseLogs = state.case[action.id].case_log || [];
      newCaseLogs.push(action.data);

      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_ADD_LOG: "success" },
        case: {
          ...state.case,
          [action.id]: { ...state.case[action.id], case_log: newCaseLogs },
        },
      };
      break;
    case caseConstants.CASE_ADD_LOG_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_ADD_LOG: action.error },
      };
      break;

    // CASE_SEND_EMAIL_REQUEST
    case caseConstants.CASE_SEND_EMAIL_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_SEND_EMAIL: "request" },
      };
      break;
    case caseConstants.CASE_SEND_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_SEND_EMAIL: "success" },
      };
      break;
    case caseConstants.CASE_SEND_EMAIL_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_SEND_EMAIL: action.error },
      };
      break;

    // CASE_UPDATE_IMPRINT_REQUEST
    case caseConstants.CASE_UPDATE_IMPRINT_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_IMPRINT: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_IMPRINT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_IMPRINT: "success" },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            website_contact_details: {
              ...state.case[action.id].website_contact_details,
              ...action.data,
            },
          },
        },
      };
      break;
    case caseConstants.CASE_UPDATE_IMPRINT_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_IMPRINT: action.error },
      };
      break;

    // CASE_UPDATE_FINDING_IMAGE_DATA_REQUEST
    case caseConstants.CASE_UPDATE_FINDING_IMAGE_DATA_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_FINDING_IMAGE_DATA: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_FINDING_IMAGE_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_FINDING_IMAGE_DATA: "success" },
      };
      // state = {...state, loading: false,
      // case: {...state.case, [action.id]: {...state.case[action.id], website_contact_details: action.data}}};
      break;
    case caseConstants.CASE_UPDATE_FINDING_IMAGE_DATA_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: {
          ...state.status,
          CASE_UPDATE_FINDING_IMAGE_DATA: action.error,
        },
      };
      break;

    // CASE_GET_CHARGE_DETAILS_REQUEST
    case caseConstants.CASE_GET_CHARGE_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_GET_CHARGE_DETAILS: "request" },
      };
      break;
    case caseConstants.CASE_GET_CHARGE_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_GET_CHARGE_DETAILS: "success" },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            charge_details: action.charge_details,
          },
        },
      };
      break;
    case caseConstants.CASE_GET_CHARGE_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_GET_CHARGE_DETAILS: action.error },
      };
      break;

    // CASE_UPDATE_FINDING_PRICE_REQUEST
    case caseConstants.CASE_UPDATE_FINDING_PRICE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_FINDING_PRICE: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_FINDING_PRICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_FINDING_PRICE: "success" },
      };
      break;
    case caseConstants.CASE_UPDATE_FINDING_PRICE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_FINDING_PRICE: action.error },
      };
      break;

    // CASE_UPDATE_CLAIM_DETAILS_REQUEST
    case caseConstants.CASE_UPDATE_CLAIM_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_CLAIM_DETAILS: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_CLAIM_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_CLAIM_DETAILS: "success" },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            claim_details: {
              ...state.case[action.id].claim_details,
              ...action.data,
            },
          },
        },
      };
      break;
    case caseConstants.CASE_UPDATE_CLAIM_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_CLAIM_DETAILS: action.error },
      };
      break;

    // CASE_UPDATE_STATE_ACTIVATE_REQUEST
    case caseConstants.CASE_UPDATE_STATE_ACTIVATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_STATE_ACTIVATE: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_STATE_ACTIVATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_UPDATE_STATE_ACTIVATE:
            !action.results.email_was_sent &&
            action.results.flow_has_initial_email
              ? "not sent"
              : "success",
        },
      };
      break;
    case caseConstants.CASE_UPDATE_STATE_ACTIVATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_STATE_ACTIVATE: action.error },
      };
      break;

    // CASE_UPDATE_STATE_CANCEL_REQUEST
    case caseConstants.CASE_UPDATE_STATE_CANCEL_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_STATE_CANCEL: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_STATE_CANCEL_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_STATE_CANCEL: "success" },
      };
      break;
    case caseConstants.CASE_UPDATE_STATE_CANCEL_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_STATE_CANCEL: action.error },
      };
      break;

    // CASE_UPDATE_STATE_RESET_REQUEST
    case caseConstants.CASE_UPDATE_STATE_RESET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_STATE_RESET: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_STATE_RESET_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_STATE_RESET: "success" },
      };
      break;
    case caseConstants.CASE_UPDATE_STATE_RESET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_STATE_RESET: action.error },
      };
      break;

    // CASE_UPDATE_COUNTRY_REQUEST
    case caseConstants.CASE_UPDATE_COUNTRY_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_COUNTRY: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_COUNTRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_COUNTRY: "success" },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            country: action.data.country,
          },
        },
      };
      break;
    case caseConstants.CASE_UPDATE_COUNTRY_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_COUNTRY: action.error },
      };
      break;

    // CASE_ADD_ADDITIONAL_FEES_REQUEST
    case caseConstants.CASE_ADD_ADDITIONAL_FEES_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_ADD_ADDITIONAL_FEES: "request" },
      };
      break;
    case caseConstants.CASE_ADD_ADDITIONAL_FEES_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_ADD_ADDITIONAL_FEES: "success" },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            claim_details: {
              ...state.case[action.id].claim_details,
              additional_fees: action.data,
            },
          },
        },
      };
      break;
    case caseConstants.CASE_ADD_ADDITIONAL_FEES_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_ADD_ADDITIONAL_FEES: action.error },
      };
      break;

    // CASE_UPDATE_LICENSE_STATE_REQUEST
    case caseConstants.CASE_UPDATE_LICENSE_STATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_LICENSE_STATE: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_LICENSE_STATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        trigger_sending_email_failed: action.result.sending_email_failed,
        status: { ...state.status, CASE_UPDATE_LICENSE_STATE: "success" },
      };
      break;
    case caseConstants.CASE_UPDATE_LICENSE_STATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_LICENSE_STATE: action.error },
      };
      break;

    case caseConstants.CASE_RESTORE_TRIGGER_SENDING_EMAIL_FAILED:
      state = {
        ...state,
        trigger_sending_email_failed: false,
      };
      break;

    // CASE_DELETE_LICENSE_REQUEST
    case caseConstants.CASE_DELETE_LICENSE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_DELETE_LICENSE: "request" },
      };
      break;
    case caseConstants.CASE_DELETE_LICENSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_DELETE_LICENSE: "success" },
      };
      break;
    case caseConstants.CASE_DELETE_LICENSE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_DELETE_LICENSE: action.error },
      };
      break;

    // CASE_DELETE_FINDING_REQUEST
    case caseConstants.CASE_DELETE_FINDING_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_DELETE_FINDING: "request" },
      };
      break;
    case caseConstants.CASE_DELETE_FINDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_DELETE_FINDING: "success" },
      };
      break;
    case caseConstants.CASE_DELETE_FINDING_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_DELETE_FINDING: action.error },
      };
      break;

    // CASE_ADD_FINDING_REQUEST
    case caseConstants.CASE_ADD_FINDING_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_ADD_FINDING: "request" },
      };
      break;
    case caseConstants.CASE_ADD_FINDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_ADD_FINDING: "success" },
      };
      break;
    case caseConstants.CASE_ADD_FINDING_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_ADD_FINDING: action.error },
      };
      break;

    case LicenseConstants.UPLOAD_LICENSE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_VALIDATE_FINDING: "request" },
      };
      break;
    case LicenseConstants.UPLOAD_LICENSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        case: {
          ...state.case,
          [action.caseId]: {
            ...state.case[action.caseId],
            findings: state.case[action.caseId].findings.map((finding) => {
              if (finding.id === action.findingId) {
                finding.license = action.results;
              }
              return finding;
            }),
          },
        },
        status: { ...state.status, CASE_VALIDATE_FINDING: "success" },
      };

      break;
    case LicenseConstants.UPLOAD_LICENSE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_VALIDATE_FINDING: action.error },
      };
      break;

    // CASE_ADD_FLOW_REQUEST
    case caseConstants.CASE_ADD_FLOW_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_ADD_FLOW: "request" },
      };
      break;
    case caseConstants.CASE_ADD_FLOW_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_ADD_FLOW: "success" },
      };
      break;
    case caseConstants.CASE_ADD_FLOW_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_ADD_FLOW: action.error },
      };
      break;

    // CASE_ADD_TRANSLATION_REQUEST
    case caseConstants.CASE_ADD_TRANSLATION_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_ADD_TRANSLATION: "request" },
      };
      break;
    case caseConstants.CASE_ADD_TRANSLATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_ADD_TRANSLATION: "success" },
      };
      break;
    case caseConstants.CASE_ADD_TRANSLATION_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_ADD_TRANSLATION: action.error },
      };
      break;

    // CASE_SAVE_EMAIL_TO_LOG_REQUEST
    case caseConstants.CASE_SAVE_EMAIL_TO_LOG_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_SAVE_EMAIL_TO_LOG: "request" },
      };
      break;
    case caseConstants.CASE_SAVE_EMAIL_TO_LOG_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_SAVE_EMAIL_TO_LOG: "success" },
      };
      break;
    case caseConstants.CASE_SAVE_EMAIL_TO_LOG_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_SAVE_EMAIL_TO_LOG: action.error },
      };
      break;

    // CASE_SAVE_EMAIL_TO_LOG_REQUEST
    case caseConstants.CASE_UPDATE_DEADLINE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASE_UPDATE_DEADLINE: "request" },
      };
      break;
    case caseConstants.CASE_UPDATE_DEADLINE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_UPDATE_DEADLINE: "success",
        },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            reminder_name: action.deadline ? action.deadline.name : null,
            reminder_date: action.deadline
              ? typeof action.deadline.date != "string"
                ? action.deadline.date.toISOString()
                : action.deadline.date
              : null,
          },
        },
      };
      break;
    case caseConstants.CASE_UPDATE_DEADLINE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASE_UPDATE_DEADLINE: action.error },
      };
      break;
    case caseConstants.CASE_UPDATE_DEADLINE_FAILURE_RESET:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, CASE_UPDATE_DEADLINE: "" },
      };
      break;
    case caseConstants.CASE_APPLY_TEMPLATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: {
          ...state.status,
          CASE_APPLY_TEMPLATE: "request",
        },
      };
      break;
    case caseConstants.CASE_APPLY_TEMPLATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_APPLY_TEMPLATE: "success",
        },
      };
      break;
    case caseConstants.CASE_APPLY_TEMPLATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: {
          ...state.status,
          CASE_APPLY_TEMPLATE: action.error,
        },
      };
      break;

    case caseConstants.CASE_TOGGLE_AUTOMATION_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: {
          ...state.status,
          CASE_TOGGLE_AUTOMATION: "request",
        },
      };
      break;
    case caseConstants.CASE_TOGGLE_AUTOMATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_TOGGLE_AUTOMATION: "success",
        },
        case: {
          ...state.case,
          [action.id]: {
            ...state.case[action.id],
            overview: {
              ...state.case[action.id].overview,
              num_scheduled_emails_paused:
                action.state === caseConstants.CASE_AUTOMATION_STATE_SCHEDULED
                  ? 0
                  : state.case[action.id].overview
                      .num_scheduled_emails_scheduled,

              num_scheduled_emails_scheduled:
                action.state === caseConstants.CASE_AUTOMATION_STATE_PAUSED
                  ? 0
                  : state.case[action.id].overview.num_scheduled_emails_paused,
            },
          },
        },
      };
      break;
    case caseConstants.CASE_TOGGLE_AUTOMATION_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: {
          ...state.status,
          CASE_TOGGLE_AUTOMATION: action.error,
        },
      };
      break;

    case caseConstants.CASE_GENERATE_INVOICE_REQUEST:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: {
          ...state.status,
          CASE_GENERATE_INVOICE: "request",
        },
      };
      break;

    case caseConstants.CASE_GENERATE_INVOICE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: {
          ...state.status,
          CASE_GENERATE_INVOICE: "success",
        },
      };
      break;

    case caseConstants.CASE_GENERATE_INVOICE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: {
          ...state.status,
          CASE_GENERATE_INVOICE: action.error,
        },
      };
      break;

    case caseConstants.CASE_DECREASE_FAILED:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_TOGGLE_AUTOMATION: "success",
        },
        case: {
          ...state.case,
          [action.caseId]: {
            ...state.case[action.caseId],
            overview: {
              ...state.case[action.caseId].overview,
              [action.typeFailedEmails]:
                state.case[action.caseId].overview[action.typeFailedEmails] > 0
                  ? state.case[action.caseId].overview[
                      action.typeFailedEmails
                    ] - 1
                  : 0,
            },
          },
        },
      };
      break;

    default:
      break;
  }

  return state;
}
