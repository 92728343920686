import { invoiceTemplateConstants } from "@_constants/invoiceTemplate.constants";
import { invoiceTemplateService } from "@_services/invoiceTemplate.service";

export const invoiceTemplateActions = {
  getInvoiceTemplates,
  getInvoicesList,
  getInvoiceTemplate,
  createInvoiceTemplate,
  deleteInvoiceTemplate,
  addInvoiceTemplateState,
  updateInvoiceTemplate,
  clearStatusMessage,
  previewInvoiceTemplate,
  downloadInvoice,
  downloadInvoicesList,
  cancelInvoice,
  selectInvoiceTemplate,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_CLEAR_STATUS_MESSAGE,
      stateName,
    };
  }
}

function selectInvoiceTemplate(id) {
  return (dispatch) => {
    dispatch(select(id));
  };

  function select() {
    return { type: invoiceTemplateConstants.SELECT_INVOICE_TEMPLATE, id };
  }
}

function getInvoiceTemplates() {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService.getInvoiceTemplates().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: invoiceTemplateConstants.INVOICE_TEMPLATES_GET_REQUEST };
  }
  function success(data) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATES_GET_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATES_GET_FAILURE,
      error,
    };
  }
}

function getInvoiceTemplate(id) {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService.getInvoiceTemplate(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: invoiceTemplateConstants.INVOICE_TEMPLATE_GET_REQUEST };
  }
  function success(data) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_GET_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_GET_FAILURE,
      error,
    };
  }
}

function getInvoicesList(filter) {
  return (dispatch) => {
    dispatch(request());

    invoiceTemplateService.getInvoicesList(filter).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: invoiceTemplateConstants.INVOICES_LIST_GET_REQUEST };
  }
  function success(data) {
    return {
      type: invoiceTemplateConstants.INVOICES_LIST_GET_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICES_LIST_GET_FAILURE,
      error,
    };
  }
}

function createInvoiceTemplate(data) {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService.createInvoiceTemplate(data).then(
      (data) => {
        dispatch(success(data.invoice_template_id));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
    function request() {
      return { type: invoiceTemplateConstants.INVOICE_TEMPLATE_CREATE_REQUEST };
    }
    function success(id) {
      return {
        type: invoiceTemplateConstants.INVOICE_TEMPLATE_CREATE_SUCCESS,
        id,
      };
    }
    function failure(error) {
      return {
        type: invoiceTemplateConstants.INVOICE_TEMPLATE_CREATE_FAILURE,
        error,
      };
    }
  };
}

function addInvoiceTemplateState(addTemplate) {
  return (dispatch) => {
    dispatch(add(addTemplate));
  };

  function add(addTemplate) {
    return {
      type: invoiceTemplateConstants.ADD_INVOICE_TEMPLATE,
      addTemplate,
    };
  }
}

function updateInvoiceTemplate(template) {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService.updateInvoiceTemplate(template).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: invoiceTemplateConstants.INVOICE_TEMPLATE_UPDATE_REQUEST };
  }
  function success(data) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_UPDATE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_UPDATE_FAILURE,
      error,
    };
  }
}

function deleteInvoiceTemplate(id) {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService.deleteInvoiceTemplate(id).then(
      () => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
    function request() {
      return { type: invoiceTemplateConstants.INVOICE_TEMPLATE_DELETE_REQUEST };
    }
    function success(id) {
      return {
        type: invoiceTemplateConstants.INVOICE_TEMPLATE_DELETE_SUCCESS,
        id,
      };
    }
    function failure(error) {
      return {
        type: invoiceTemplateConstants.INVOICE_TEMPLATE_DELETE_FAILURE,
        error,
      };
    }
  };
}
function previewInvoiceTemplate(template) {
  return async (dispatch) => {
    dispatch(request());
    const response = await invoiceTemplateService.previewInvoiceTemplate(
      template
    );
    if (!response.ok) {
      dispatch(failure(response.statusText));
      console.log(response.statusText);
      return;
    }
    const blob = await response.blob().catch((error) => {
      dispatch(failure(error));

      return;
    });
    const url = window.URL.createObjectURL(
      new Blob([blob], { type: "application/pdf" })
    );
    window.open(url, "_blank");
    dispatch(success());
  };
  function request() {
    return { type: invoiceTemplateConstants.INVOICE_TEMPLATE_PREVIEW_REQUEST };
  }
  function success() {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_PREVIEW_SUCCESS,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICE_TEMPLATE_PREVIEW_FAILURE,
      error,
    };
  }
}
// for now calling the service directly as the handle response helper function is not handling the blob/file recieved from the backend correctly
function downloadInvoice(caseId, invoiceId, fileName, action) {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService
      .downloadInvoice(caseId, invoiceId, fileName)
      .then(
        (response) => response.blob(),
        (error) => {
          dispatch(failure(error));
        }
      )
      .then(
        (blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          if (action === "download") {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          } else if (action === "view") {
            window.open(url, "_blank");
          }
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: invoiceTemplateConstants.INVOICE_DOWNLOAD_REQUEST };
  }
  function success() {
    return {
      type: invoiceTemplateConstants.INVOICE_DOWNLOAD_SUCCESS,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICE_DOWNLOAD_FAILURE,
      error,
    };
  }
}

// for now calling the service directly as the handle response helper function is not handling the blob/file recieved from the backend correctly
function downloadInvoicesList(filter) {
  return async (dispatch) => {
    dispatch(request());
    const response = await invoiceTemplateService.downloadInvoicesList(filter);

    if (!response.ok) {
      dispatch(failure(response.statusText));
      console.log(response.statusText);
      return;
    }
    const blob = await response.blob().catch((error) => {
      dispatch(failure(error));

      return;
    });
    const url = window.URL.createObjectURL(
      new Blob([blob], { type: "application/zip" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "invoices");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    dispatch(success());
  };

  function request() {
    return { type: invoiceTemplateConstants.INVOICES_LIST_DOWNLOAD_REQUEST };
  }
  function success() {
    return {
      type: invoiceTemplateConstants.INVOICES_LIST_DOWNLOAD_SUCCESS,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICES_LIST_DOWNLOAD_FAILURE,
      error,
    };
  }
}

function cancelInvoice(caseId, cancelledInvoiceId) {
  return (dispatch) => {
    dispatch(request());
    invoiceTemplateService.cancelInvoice(caseId, cancelledInvoiceId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceTemplateConstants.INVOICE_CANCEL_REQUEST };
  }
  function success(data) {
    return {
      type: invoiceTemplateConstants.INVOICE_CANCEL_SUCCESS,
      data,
      cancelledInvoiceId,
    };
  }
  function failure(error) {
    return {
      type: invoiceTemplateConstants.INVOICE_CANCEL_FAILURE,
      error,
    };
  }
}
