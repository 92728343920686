import { authConstants } from "@_constants/auth.constants";
import { userConstants } from "@_constants/user.constants";
import { userService } from "@_services/user.service";
import { history } from "@_utils/helpers";

export const userActions = {
  getUsers,
  addUser,
  deleteUser,
  clearStatusMessage,
  activateUser,
  deactivateUser,
  addUserState,
  selectUser,
  getSignup,
  updateSignup,
  resetName,
  resetPassword,
  updateUser,
  sendPasswordLink,
  getPasswordLink,
  getSignature,
  newSignature,
  newPassword,
  getOnboardingStatus,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: userConstants.USER_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function addUserState(data) {
  return (dispatch) => {
    dispatch(request(data));
  };

  function request() {
    return { type: userConstants.ADD_USER, data };
  }
}

function selectUser(data) {
  return (dispatch) => {
    dispatch(request(data));
  };

  function request() {
    return { type: userConstants.SELECT_USER, data };
  }
}

function getUsers() {
  return (dispatch) => {
    dispatch(request());
    userService.getUsers().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_GET_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_GET_FAILURE, error };
  }
}

function addUser(data) {
  return (dispatch) => {
    dispatch(request(data));
    userService.addUser(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_POST_REQUEST, data };
  }
  function success(data) {
    return { type: userConstants.USER_POST_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_POST_FAILURE, error };
  }
}

function deleteUser(id) {
  return (dispatch) => {
    dispatch(request());
    userService.deleteUser(id).then(
      (data) => {
        dispatch(success(id, data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_DELETE_REQUEST };
  }
  function success(id, data) {
    return { type: userConstants.USER_DELETE_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: userConstants.USER_DELETE_FAILURE, error };
  }
}

function activateUser(id, data) {
  return (dispatch) => {
    dispatch(request());
    userService.activateUser(id, data).then(
      (data) => {
        dispatch(success(id, data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_ACTIVATE_REQUEST };
  }
  function success(id, data) {
    return {
      type: userConstants.USER_ACTIVATE_SUCCESS,
      id,
      data,
    };
  }
  function failure(error) {
    return {
      type: userConstants.USER_ACTIVATE_FAILURE,
      error,
    };
  }
}

function deactivateUser(id, data) {
  return (dispatch) => {
    dispatch(request());
    userService.deactivateUser(id, data).then(
      (data) => {
        dispatch(success(id, data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_DEACTIVATE_REQUEST };
  }
  function success(id, data) {
    return {
      type: userConstants.USER_DEACTIVATE_SUCCESS,
      id,
      data,
    };
  }
  function failure(error) {
    return {
      type: userConstants.USER_DEACTIVATE_FAILURE,
      error,
    };
  }
}

function getSignup(id) {
  return (dispatch) => {
    dispatch(request());
    userService.getSignup(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_GET_SIGNUP_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_GET_SIGNUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_GET_SIGNUP_FAILURE, error };
  }
}

function updateSignup(id, data) {
  return (dispatch) => {
    dispatch(request());
    userService.updateSignup(id, data).then(
      (data) => {
        dispatch(success(data));
        dispatch(userAuth(data));
        history.push("/cases");
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_PUT_SIGNUP_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_PUT_SIGNUP_SUCCESS, data };
  }
  function userAuth(data) {
    return { type: authConstants.SIGNED_UP, data };
  }
  function failure(error) {
    return { type: userConstants.USER_PUT_SIGNUP_FAILURE, error };
  }
}

function updateUser(id, data) {
  return (dispatch) => {
    dispatch(request());
    userService.updateUser(id, data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_UPDATE_FAILURE, error };
  }
}

function resetName(data) {
  return (dispatch) => {
    dispatch(request());
    userService.resetName(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_RESET_NAME_REQUES };
  }
  function success(data) {
    return { type: userConstants.USER_RESET_NAME_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_RESET_NAME_FAILURE, error };
  }
}

function resetPassword(data) {
  return (dispatch) => {
    dispatch(request());
    userService.resetPassword(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_RESET_PASSWORD_REQUES };
  }
  function success(data) {
    return { type: userConstants.USER_RESET_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_RESET_PASSWORD_FAILURE, error };
  }
}

// forgot password

function sendPasswordLink(data) {
  return (dispatch) => {
    dispatch(request());
    userService.sendPasswordLink(data).then(
      (res) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_PUT_PASSWORD_LINK_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_PUT_PASSWORD_LINK_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_PUT_PASSWORD_LINK_FAILURE, error };
  }
}

function getPasswordLink(id) {
  return (dispatch) => {
    dispatch(request());
    userService.getPasswordLink(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_GET_PASSWORD_LINK_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_GET_PASSWORD_LINK_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_GET_PASSWORD_LINK_FAILURE, error };
  }
}

function getSignature() {
  return (dispatch) => {
    dispatch(request());
    userService.getSignature().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_GET_SIGNATURE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_GET_SIGNATURE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_GET_SIGNATURE_FAILURE, error };
  }
}

function newSignature(signature) {
  return (dispatch) => {
    dispatch(request());
    // clean out signature because editor will send '<p><br></p>' when its empty
    userService
      .newSignature(
        signature.signature === "<p><br></p>" ? { signature: "" } : signature
      )
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() {
    return { type: userConstants.USER_PUT_NEW_SIGNATURE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_PUT_NEW_SIGNATURE_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_PUT_NEW_SIGNATURE_FAILURE, error };
  }
}

function newPassword(id, data) {
  return (dispatch) => {
    dispatch(request());
    userService.newPassword(id, data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_PUT_NEW_PASSWORD_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_PUT_NEW_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_PUT_NEW_PASSWORD_FAILURE, error };
  }
}

function getOnboardingStatus() {
  return (dispatch) => {
    dispatch(request());
    userService.getOnboardingStatus().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USER_GET_ONBOARDING_STATUS_REQUEST };
  }
  function success(data) {
    return { type: userConstants.USER_GET_ONBOARDING_STATUS_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.USER_GET_ONBOARDING_STATUS_FAILURE, error };
  }
}
