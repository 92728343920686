import { emailSetupConstants } from "@_constants/email_setup.constants";
import { emailSetupService } from "@_services/email_setup.service";

export const emailSetupActions = {
  getEmailSetup,
  clearStatusMessage,
  updateOutboundEmailSetup,
  activateOutboundEmailSetup,
  validateOutboundEmailSetup,
  activateInboundEmailSetup,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return {
      type: emailSetupConstants.EMAIL_SETUP_CLEAR_STATUS_MESSAGE,
      stateName,
    };
  }
}

function getEmailSetup() {
  return (dispatch) => {
    dispatch(request());
    emailSetupService.getEmailSetup().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailSetupConstants.EMAIL_SETUP_GET_REQUEST };
  }
  function success(data) {
    return { type: emailSetupConstants.EMAIL_SETUP_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailSetupConstants.EMAIL_SETUP_GET_FAILURE, error };
  }
}

function updateOutboundEmailSetup(data) {
  return (dispatch) => {
    dispatch(request());
    emailSetupService.updateOutboundEmailSetup(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_UPDATE_FAILURE, error };
  }
}

function activateOutboundEmailSetup(data) {
  return (dispatch) => {
    dispatch(request());
    emailSetupService.activateOutboundEmailSetup(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_ACTIVATE_REQUEST };
  }
  function success(data) {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_ACTIVATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_ACTIVATE_FAILURE, error };
  }
}

function validateOutboundEmailSetup(data, type) {
  return (dispatch) => {
    dispatch(request());
    emailSetupService.validateOutboundEmailSetup(data, type).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_VALIDATE_REQUEST };
  }
  function success(data) {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_VALIDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailSetupConstants.OUTBOUND_EMAIL_VALIDATE_FAILURE, error };
  }
}

function activateInboundEmailSetup() {
  return (dispatch) => {
    dispatch(request());
    emailSetupService.activateInboundEmailSetup().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: emailSetupConstants.INBOUND_EMAIL_ACTIVATE_REQUEST };
  }
  function success(data) {
    return { type: emailSetupConstants.INBOUND_EMAIL_ACTIVATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: emailSetupConstants.INBOUND_EMAIL_ACTIVATE_FAILURE, error };
  }
}
