import React, { useState, useEffect } from "react";
import styles from "./PaymentSetup.module.css";
import { authService } from "@_services/auth.service";
import StripeLogo from "@_assets/images/stripe_logo.png";

import BusinessIcon from "@material-ui/icons/Business";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function PaymentSetup() {
  const [stripeUrl, setStripeUrl] = useState("");
  const [isConnected, setIsConnected] = useState();

  useEffect(() => {
    function checkStripeStatus() {
      authService.getStripe().then(
        (results) => {
          if (results.status !== "connected") {
            setStripeUrl(results.onboarding_url);
            setIsConnected(false);
          } else {
            setIsConnected(true);
            // either connected or diffrent status response with 200
          }
        },
        (error) => {
          // TODO alert user
          checkStripeStatus();
        }
      );
    }

    checkStripeStatus();
  }, []);

  return (
    <div className={styles.root}>
      {" "}
      <div className={styles.account_title}>
        <BusinessIcon className={styles.business_icon} />
        <h2>Account </h2>
        <ArrowForwardIosIcon fontSize="small" className={styles.arrow_icon} />
        <h2>Payment Setup</h2>
      </div>
      <div className={styles.stripe_container}>
        <div className={styles.logo_container}>
          <img
            src={StripeLogo}
            alt="Stripe Logo"
            className={styles.stripe_logo}
          />
        </div>
        <div className={styles.text_container}>
          <div>
            <p>
              Fair Licensing uses Stripe for payment processing. <br></br>
              Connect the portal to Stripe and receive payments<br></br>{" "}
              straight to your account.
            </p>
          </div>

          <div>
            {isConnected ? (
              <div className={styles.connected_account}>
                <CheckCircleIcon className={styles.check_circle_icon} />{" "}
                <p>Your account is connected.</p>
              </div>
            ) : (
              <Button className={styles.confirm_btn}>
                <a href={stripeUrl} target="_blank" rel="noopener noreferrer">
                  Connect to stripe
                </a>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSetup;
