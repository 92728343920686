import { addressConstants } from "@_constants/address.constants";
import { addressService } from "@_services/address.service";

export const addressActions = {
  getAddress,
  updateAddress,
};

function getAddress() {
  return (dispatch) => {
    dispatch(request());
    addressService.getAddress().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: addressConstants.ADDRESS_GET_REQUEST };
  }
  function success(data) {
    return { type: addressConstants.ADDRESS_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: addressConstants.ADDRESS_GET_FAILURE, error };
  }
}

function updateAddress(data) {
  return (dispatch) => {
    dispatch(request());
    addressService.updateAddress(data).then(
      (results) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: addressConstants.ADDRESS_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: addressConstants.ADDRESS_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: addressConstants.ADDRESS_UPDATE_FAILURE, error };
  }
}
