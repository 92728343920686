import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { catchError, getUserDataFromJwt } from "@_utils/helpers";
import { emailActions } from "@_actions/email_flows.actions";
import { templateActions } from "@_actions/template.actions";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EmailFlowRow from "@_components/EmailFlowRow/EmailFlowRow";
import EmailTemplate from "@_components/EmailTemplate/EmailTemplate";
import UpgradePlanpopup from "@_components/popups/UpgradePlanPopup/UpgradePlanPopup.js";
import UpgradeImage from "@_assets/images/add_email_flows.png";
import subscriptionCopy from "@_configs/subscription.config.js";
import styles from "./EmailFlows.module.css";

import { Button } from "@material-ui/core";
import NewEmailFlowPopup from "@_components/popups/NewEmailFlowPopup/NewEmailFlowPopup";
import { emailService } from "@_services/email_flows.service";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function EmailFlows() {
  const dispatch = useDispatch();

  const jwtData = getUserDataFromJwt();

  const emailFlowReducer = useSelector((state) => state.emailFlowReducer);
  const templateReducer = useSelector((state) => state.templateReducer);
  const [emailFlow, setEmailFlow] = useState([]);
  const [newFlow, setNewFlow] = useState({
    name: "",
  });
  const [openNewEmailFlowPopup, setOpenNewEmailFlowPopup] = useState(false);
  const [presetNames, setPresetNames] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState("");
  const [newEmailFlowName, setNewEmailFlowName] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [displayUpgrade, setDisplayUpgrade] = useState(false);
  const [selectedFlowIndex, setSelectedFlowIndex] = useState(-1);
  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);

  const { title, subtitle, copy_1, upgradeButton } =
    subscriptionCopy.emailFlow[jwtData.subscription.toLowerCase()];

  const handleInputChange = (e) => {
    if (
      jwtData.subscription.toLowerCase() !== "enterprise" &&
      emailFlowReducer.emailFlows.length >=
        subscriptionReducer.subscriptionConfig.max_email_flows
    ) {
      setDisplayUpgrade(true);
    } else {
      const { name, value } = e.target;
      setNewFlow((newFlow) => ({
        ...newFlow,
        [name]: value,
      }));
    }
  };

  const handleSubmitInput = (e) => {
    e.preventDefault();
    if (newFlow.name) {
      dispatch(emailActions.addEmail({ name: newFlow.name }));
    }
    emailFlow.push(newFlow);
    setNewFlow({ name: "" });
  };

  const handleConfirmNewEmailFlow = () => {
    if (
      jwtData.subscription.toLowerCase() !== "enterprise" &&
      emailFlowReducer.emailFlows.length >=
        subscriptionReducer.subscriptionConfig.max_email_flows
    ) {
      setDisplayUpgrade(true);
    } else {
      dispatch(
        emailActions.addEmail({
          name: newEmailFlowName,
          preset_name: selectedPreset,
        })
      );
      setEmailFlow([...emailFlow, newEmailFlowName]);
    }
    handleCloseNewEmailFlowPopup();
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleOpenNewEmailFlowPopup = async () => {
    try {
      const flowPresetNames = await emailService.getEmailFlowPresets();
      setPresetNames(flowPresetNames);
      setOpenNewEmailFlowPopup(true);
    } catch (error) {
      alert("There was a problem loading the presets please try again");
    }
  };

  const handleCloseNewEmailFlowPopup = () => {
    setOpenNewEmailFlowPopup(false);
    setNewEmailFlowName("");
    setSelectedPreset("");
  };

  const handleSelectPreset = (event) => {
    const { options } = event.target;
    let value = "";
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value = options[i].value;
      }
    }
    setSelectedPreset(value);
  };

  const handleNewEmailFlowName = (event) => {
    setNewEmailFlowName(event.target.value);
  };

  useEffect(() => {
    emailFlowReducer.emailFlows &&
      emailFlowReducer.emailFlows.map((email) => {
        return setEmailFlow([...emailFlowReducer.emailFlows]);
      });
  }, [emailFlowReducer.emailFlows]);

  useEffect(() => {
    dispatch(emailActions.getEmail());
  }, [dispatch]);

  // ERROR AND SUCCESS MESSAGES

  // display email POST success & error
  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_POST === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      dispatch(emailActions.clearStatusMessage("EMAIL_FLOW_POST"));
    } else if (catchError(emailFlowReducer.status.EMAIL_FLOW_POST)) {
      setErrorMessage(emailFlowReducer.status.EMAIL_FLOW_POST);
      setOpenErrorSnackbar(true);
      dispatch(emailActions.clearStatusMessage("EMAIL_FLOW_POST"));
    } else return;
  }, [dispatch, emailFlowReducer.status.EMAIL_FLOW_POST]);

  // display email PUT success & error
  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      dispatch(emailActions.clearStatusMessage("EMAIL_FLOW_PUT"));
    } else if (catchError(emailFlowReducer.status.EMAIL_FLOW_PUT)) {
      setErrorMessage(emailFlowReducer.status.EMAIL_FLOW_PUT);
      setOpenErrorSnackbar(true);
      dispatch(emailActions.clearStatusMessage("EMAIL_FLOW_PUT"));
    } else return;
  }, [dispatch, emailFlowReducer.status]);

  // display email DELETE success & error
  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_DELETE === "success") {
      setSuccessMessage("deletion successful!");
      setOpenSnackbar(true);
      dispatch(emailActions.clearStatusMessage("EMAIL_FLOW_DELETE"));
    } else if (catchError(emailFlowReducer.status.EMAIL_FLOW_DELETE)) {
      setOpenErrorSnackbar(true);
      setErrorMessage(emailFlowReducer.status.EMAIL_FLOW_DELETE);
      dispatch(emailActions.clearStatusMessage("EMAIL_FLOW_DELETE"));
    } else return;
  }, [openSnackbar, dispatch, emailFlowReducer.status]);

  // display template DELETE success & error
  useEffect(() => {
    if (templateReducer.status.TEMPLATE_DELETE === "success") {
      setSuccessMessage("deletion successful!");
      setOpenSnackbar(true);
      dispatch(templateActions.clearStatusMessage("TEMPLATE_DELETE"));
    } else if (catchError(templateReducer.status.TEMPLATE_DELETE)) {
      setOpenErrorSnackbar(true);
      setErrorMessage(templateReducer.status.TEMPLATE_DELETE);
      dispatch(templateActions.clearStatusMessage("TEMPLATE_DELETE"));
    } else return;
  }, [openSnackbar, dispatch, templateReducer.status]);

  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_POST === "success") {
      dispatch(emailActions.getEmail());
    }
  }, [dispatch, emailFlowReducer.status.EMAIL_FLOW_POST]);

  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_PUT === "success") {
      dispatch(emailActions.getEmail());
    }
  }, [dispatch, emailFlowReducer.status.EMAIL_FLOW_PUT]);

  useEffect(() => {
    if (emailFlowReducer.status.EMAIL_FLOW_DELETE === "success") {
      dispatch(emailActions.getEmail());
    }
  }, [dispatch, emailFlowReducer.status.EMAIL_FLOW_DELETE]);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <div className={styles.flow_list_container}>
        <Scrollbar maxHeight="calc(100vh - 140px)" height="calc(100vh - 140px)">
          <div className={styles.new_flow_button}>
            <Button onClick={handleOpenNewEmailFlowPopup} variant="outlined">
              Create New Email Flow
            </Button>
          </div>

          {emailFlowReducer.emailFlows &&
            emailFlowReducer.emailFlows.map((emailFlow, index) => {
              return (
                <EmailFlowRow
                  data={emailFlow}
                  key={emailFlow.id}
                  index={index}
                  styles={styles}
                  name={newFlow.name}
                  handleSelectedFlow={() => setSelectedFlowIndex(index)}
                  selectedFlow={index === selectedFlowIndex}
                  handleSubmitInput={handleSubmitInput}
                  handleInputChange={handleInputChange}
                />
              );
            })}
        </Scrollbar>
      </div>
      <NewEmailFlowPopup
        open={openNewEmailFlowPopup}
        presetNames={presetNames}
        name={newEmailFlowName}
        onClose={handleCloseNewEmailFlowPopup}
        onSelect={handleSelectPreset}
        onEmailFlowNameChange={handleNewEmailFlowName}
        onConfirm={handleConfirmNewEmailFlow}
        selectedPreset={selectedPreset}
      />
      <div className={styles.template_container}>
        {templateReducer.selected_template &&
        Object.keys(templateReducer.selected_template).length !== 0 ? (
          <EmailTemplate
            data={templateReducer.selected_template}
            id={templateReducer.flow_templates}
            onClickOutside
          />
        ) : null}

        {(templateReducer.selected_template &&
          Object.keys(templateReducer.selected_template).length === 0 &&
          !templateReducer.add_template) ||
        (!templateReducer.selected_template &&
          !templateReducer.add_template) ? (
          <div className={styles.template_no_data}>
            NO TEMPLATE IS SELECTED!
          </div>
        ) : null}
      </div>
      {displayUpgrade && (
        <UpgradePlanpopup
          title={title}
          subtitle={subtitle}
          upgradeButton={upgradeButton}
          copy_1={copy_1}
          imageUrl={UpgradeImage}
          open={displayUpgrade}
          close={() => setDisplayUpgrade(false)}
        />
      )}
    </div>
  );
}

export default EmailFlows;
